import { DateContainer } from "../WorkHistorySection/WorkHistoryStyled";
import {
  AddIcon,
  DateAndEducationContainer,
  DeleteIcon,
  DividerAndIcon,
  DividerMui,
  EditAndDeleteContainer,
  EditIcon,
  EducationContainer,
  EducationDescriptionContainer,
  EducationHeader,
  EducationTextContainer,
  EducationTitle,
  Title,
} from "./EducationSectionStyled";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { History, HistoryType } from "../../../../../../redux/cv/cvTypes";
import dayjs from "dayjs";
import { HistoryFormModal } from "../HistoryFormModal/HistoryFormModal";
import { removeCvHistoryItem } from "../../../../../../redux/cv/cv";
import { usePreviewContext } from "../../../../../../context/PreviewPDFContext";
import { useApi } from "../../../../../../providers/Api";

export const EducationSection = () => {
  const dispatch = useDispatch();
  const educationHistory = useSelector((state: RootState) =>
    state.cv?.history.filter((item) => item.type === HistoryType.EDUCATION)
  );
  const cvId = useSelector((state: RootState) => state.cv?._id);

  const preview = usePreviewContext();

  const [open, setOpen] = useState(false);
  const [currentHistoryItem, setCurrentHistoryItem] = useState<History | null>(
    null
  );

  const { deleteHistoryItem } = useApi();

  const formatDate = (date: number) => {
    const month = dayjs(date).get("month");
    const year = dayjs(date).get("year");
    if (month < 10) {
      return `0${month}.${year}`;
    }
    return `${month}.${year}`;
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
    setCurrentHistoryItem(null);
  };

  return (
    <EducationContainer>
      <Title />
      <DividerAndIcon>
        <DividerMui />
        {preview && <AddIcon onClick={handleOpen} />}
      </DividerAndIcon>
      {educationHistory?.map((item) => (
        <DateAndEducationContainer key={item._id}>
          <DateContainer>
            <p>
              {formatDate(item.startingDate)}-{" "}
              {item.endingDate === -1 ? "Present" : formatDate(item.endingDate)}
            </p>
          </DateContainer>
          <EducationDescriptionContainer>
            <EducationHeader>
              <EducationTextContainer>
                <EducationTitle>{item.title}</EducationTitle>
                <h5>{item.subTitle}</h5>
              </EducationTextContainer>
              {preview && (
                <EditAndDeleteContainer>
                  <EditIcon
                    onClick={() => {
                      setCurrentHistoryItem(item);
                      setOpen(true);
                    }}
                  />
                  <DeleteIcon
                    onClick={() => {
                      if (cvId) {
                        deleteHistoryItem(cvId, item._id).then(() => {
                          dispatch(removeCvHistoryItem(item._id));
                        });
                      }
                    }}
                  />
                </EditAndDeleteContainer>
              )}
            </EducationHeader>
          </EducationDescriptionContainer>
        </DateAndEducationContainer>
      ))}
      <HistoryFormModal
        open={open}
        type={HistoryType.EDUCATION}
        values={currentHistoryItem}
        onCancel={handleCancel}
      />
    </EducationContainer>
  );
};
