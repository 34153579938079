import styled from "styled-components";
import { colors } from "../../../../../style/colors";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 200px;
  background-color: ${colors.paperGrey};
  border-radius: 20px;
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 67px;
  cursor: pointer;
`;

export const CancelButtonModal = styled(Button)`
  background-color: white;
  border: 2px solid ${colors.blue500};
  color: ${colors.blue500};
`;
export const DeleteButtonModal = styled(Button)`
  background-color: ${colors.blue300};
  border: 2px solid ${colors.blue300};
  color: white;
`;

export const DeleteButton = styled.button`
  cursor: pointer;
  width: 240px;
  font-size: 17px;
  padding: 4px;
  border-radius: 67px;
  border: 2px solid white;
  color: white;
  background-color: ${colors.blue100};
`;

export const DeleteButtonImage = styled.img.attrs({
  src: "/images/deleteIconButton.svg",
  alt: "delete-icon",
})`
  margin-right: 10px;
`;

export const ModalTitle = styled(Typography).attrs({
  children: "Delete selected items",
  variant: "h5",
})`
  font-weight: bold;
  margin-bottom: 2rem;
`;
export const ModalSubtitle = styled(Typography).attrs({
  children: "Are you sure you want to delete?",
  //   variant: "h5",
})`
  /* font-weight: ; */
  margin-bottom: 2rem;
`;
