import {
  DashboardComponents,
  DashboardContainer,
  DashboardHeader,
  DashboardInterfaceContainer,
} from "./DashboardInterfaceStyles";
import { DashboardList } from "../../components/DashboardComponents/DashboardList/DashboardList";
import { DashboardCreateNew } from "../../components/DashboardComponents/DashboardCVTemplate/DashboardCreateNew/DashboardCreateNew";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CVTemplate } from "../../components/DashboardComponents/DashboardCVTemplate/CVTemplate/CVTemplate";
import { PreviewPDFProvider } from "../../context/PreviewPDFContext";
import { useRef } from "react";
import { DashboardTemplateContent } from "../../components/DashboardComponents/DashboardCVTemplate/DashboardTemplateContent/DashboardTemplateContent";

export const DashboardInterface = () => {
  const isCvSelected = !!useSelector((state: RootState) => state.cv);

  const cvTemplateRef = useRef<HTMLDivElement>(null);

  return (
    <DashboardInterfaceContainer>
      <DashboardComponents>
        <DashboardList />
        <PreviewPDFProvider>
          <DashboardContainer>
            <DashboardHeader>
              <DashboardTemplateContent
                cvTemplateRef={cvTemplateRef}
                isCvSelected={isCvSelected}
              />
            </DashboardHeader>
            {!isCvSelected ? (
              <DashboardCreateNew />
            ) : (
              <CVTemplate cvTemplateRef={cvTemplateRef} />
            )}
          </DashboardContainer>
        </PreviewPDFProvider>
      </DashboardComponents>
    </DashboardInterfaceContainer>
  );
};
