import { createSlice } from "@reduxjs/toolkit";
import { clone, remove } from "lodash";
import { Action } from "../storeTypes";
import {
  Avatar,
  Contact,
  Cv,
  History,
  InterestsType,
  LanguagesType,
  Skills,
} from "./cvTypes";

export const cvSlice = createSlice({
  name: "cv",
  initialState: null as Cv | null,
  reducers: {
    setCv(_, action: Action<Cv>) {
      return clone(action.payload);
    },

    setCvTitle(
      state,
      action: Action<{ firstName: string; lastName: string; position: string }>
    ) {
      const { firstName, lastName, position } = action.payload;

      if (state) {
        state.firstName = firstName;
        state.lastName = lastName;
        state.position = position;
      }
    },
    setCvAvatar(state, action: Action<Avatar>) {
      const { url, name } = action.payload;

      if (state) {
        if (state.avatar) {
          state.avatar.url = url;
          state.avatar.name = name;
        } else {
          state.avatar = { url, name };
        }
      }
    },
    setCvContact(state, action: Action<Contact>) {
      const { city, county, mobilePhone, email, linkedIn } = action.payload;

      if (state) {
        state.contact = { city, county, mobilePhone, email, linkedIn };
      }
    },
    setCvSkills(state, action: Action<Skills>) {
      const { list, logos } = action.payload;

      if (state) {
        state.skills.list = list;
        state.skills.logos = logos;
      }
    },
    setCvLanguages(state, action: Action<LanguagesType[]>) {
      if (state) {
        state.languages = action.payload;
      }
    },
    setCvInterests(state, action: Action<InterestsType[]>) {
      const interestObject = action.payload;

      if (state) {
        state.interests = interestObject;
      }
    },
    setCvDescription(state, action: Action<string[]>) {
      if (state) {
        state.description = action.payload;
      }
    },

    createCvHistoryItem(state, action: Action<History>) {
      state?.history.push(action.payload);
    },
    removeCvHistoryItem(state, action: Action<string>) {
      if (state?.history) {
        remove(state?.history, ({ _id }) => _id === action.payload);
      }
    },
    setCvHistoryItem(state, action: Action<History>) {
      const {
        _id,
        title,
        subTitle,
        startingDate,
        endingDate,
        description,
        footer,
        type,
        location,
      } = action.payload;

      if (state) {
        const historyItem = state.history.find((item) => item._id === _id);

        if (historyItem) {
          historyItem.title = title;
          historyItem.subTitle = subTitle;
          historyItem.startingDate = startingDate;
          historyItem.endingDate = endingDate;
          historyItem.description = description;
          historyItem.footer = footer;
          historyItem.type = type;
          historyItem.location = location;
        }
      }
    },
  },
});

export const {
  setCv,
  setCvAvatar,
  setCvTitle,
  setCvContact,
  setCvSkills,
  setCvLanguages,
  setCvInterests,
  setCvDescription,
  setCvHistoryItem,
  createCvHistoryItem,
  removeCvHistoryItem,
} = cvSlice.actions;
export default cvSlice.reducer;
