import styled from "styled-components";

type ContainerProps = {
  collapsed: boolean;
};
export const ListContainer = styled.div<ContainerProps>`
  background-color: #002b48;
  width: ${(props) => (props.collapsed ? "25%" : "10%")};
  display: flex;
  flex-direction: column;
  transition: width 0.5s ease-in-out;
  z-index: 5;
`;
