import {
  CVTemplateContainer,
  CVSideInformationsContainer,
  CVDashboardContainer,
  CVSideInformationsForResize,
  CVMainInformationsForResize,
  CVMainInformationsContainer,
} from "./CVTemplateStyled";
import { ContactSection } from "./ContactSection/ContactSection";
import { SkillsSection } from "./SkillsSection/SkillsSection";
import { LanguageSection } from "./LanguageSection/LanguageSection";
import { InterestsSection } from "./InterestsSection/InterestsSection";
import { NameAndPosition } from "./NameAndPosition/NameAndPosition";
import { AvatarSection } from "./AvatarSection/AvatarSection";
import { DescriptionSection } from "./DescriptionSection/DescriptionSection";
import { WorkHistorySection } from "./HistorySection/WorkHistorySection/WorkHistorySection";
import { EducationSection } from "./HistorySection/EducationSection/EducationSection";
import { useResizeHook } from "./CVTemplateHooks";
import { RefObject } from "react";

type Props = {
  cvTemplateRef: RefObject<HTMLDivElement>;
};

export const CVTemplate = ({ cvTemplateRef }: Props) => {
  const { size, refSide, refMain } = useResizeHook();
  return (
    <CVDashboardContainer>
      <CVTemplateContainer ref={cvTemplateRef} itemProp={`${size}px`}>
        <CVSideInformationsContainer>
          <CVSideInformationsForResize ref={refSide}>
            <AvatarSection />
            <NameAndPosition />
            <ContactSection />
            <SkillsSection />
            <LanguageSection />
            <InterestsSection />
          </CVSideInformationsForResize>
        </CVSideInformationsContainer>
        <CVMainInformationsContainer>
          <CVMainInformationsForResize ref={refMain}>
            <DescriptionSection />
            <WorkHistorySection />
            <EducationSection />
          </CVMainInformationsForResize>
        </CVMainInformationsContainer>
      </CVTemplateContainer>
    </CVDashboardContainer>
  );
};
