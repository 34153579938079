import styled from "styled-components";
import { colors } from "../../../../style/colors";
import { BurgerMenu } from "../../../muiComponents/BurgerMenu/BurgerMenu";

export const HeaderToolButtonsContainer = styled.div`
  display: flex;
`;

export const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  /* width: 998px; */
  width: 100%;
  padding: 0px 52px;
  /* padding-top: 10px; */
  margin: 0 auto;
`;

export const BackToDashboardContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 18px;
    padding-bottom: 2px;
    color: ${colors.blue500};
  }
  p {
    font-size: 18px;
    color: ${colors.blue500};
    font-weight: bold;
  }
`;
export const ButtonPrimary = styled.button`
  width: 100px;
  height: 40px;
  border-radius: 67px;
  cursor: pointer;
  margin: 3px;
  background-color: ${colors.blue300};
  border: 2px solid ${colors.blue300};
  color: white;
`;

export const ButtonSecondary = styled(ButtonPrimary)`
  background-color: ${colors.lightGrey};
  color: ${colors.blue500};
  border-color: ${colors.blue500};
`;

export const ButtonText = styled(ButtonPrimary)`
  background-color: ${colors.lightGrey};
  border-color: ${colors.lightGrey};
  color: ${colors.blue500};
`;

export const ModalText = styled.p`
  text-align: center;
  font-size: 30px;
  padding-top: 100px;
  font-weight: bold;
  color: ${colors.blue300};
`;

export const DashboardUserNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20%;
  padding: 0px 52px;
  /* margin-top: 23px; */
  /* padding-left: 100px; */
  /* padding-right: 50px; */
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* margin-bottom: 10px; */
`;

export const ProfileButton = styled(BurgerMenu)`
  margin-left: auto;
`;

export const CvNameText = styled.div`
  display: flex;
  gap: 10px;
  h2 {
    font-weight: bold;
    font-size: 25px;
  }
`;
