import styled from "styled-components";

export const FirebaseImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  width: 100%;
  justify-content: right;
  overflow-y: scroll;
  padding: 0px 16px;

  img {
    height: 100px;
    width: 100px;
    cursor: pointer;
  }
`;

export const FirebaseImagesHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseImage = styled.img`
  cursor: pointer;
  width: 30px;
`;

export const FirebaseHeaderDivider = styled.img`
  width: 100%;
`;
