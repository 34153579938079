import {
  DividerAndIcon,
  AddIcon,
  EditIcon,
  DeleteIcon,
  DividerMui,
} from "../../SidebarSection/SideBarSectionStyled";
import dayjs from "dayjs";
import {
  DateAndJobDescriptionContainer,
  DateContainer,
  EditAndDeleteContainer,
  JobDescriptionContainer,
  OccupationHeader,
  OccupationContainer,
  OccupationTitle,
  ResponsibilitiesContainer,
  ResponsibilitiesTitle,
  TechnologiesContainer,
  Title,
  WorkHistoryMainContainer,
  TechTit,
} from "./WorkHistoryStyled";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { History, HistoryType } from "../../../../../../redux/cv/cvTypes";
import { HistoryFormModal } from "../HistoryFormModal/HistoryFormModal";
import { removeCvHistoryItem } from "../../../../../../redux/cv/cv";
import { usePreviewContext } from "../../../../../../context/PreviewPDFContext";
import { useApi } from "../../../../../../providers/Api";

export const WorkHistorySection = () => {
  const dispatch = useDispatch();
  const workHistory = useSelector((state: RootState) =>
    state.cv?.history.filter((item) => item.type === HistoryType.WORK)
  );
  const preview = usePreviewContext();

  const cvId = useSelector((state: RootState) => state.cv?._id);

  const [open, setOpen] = useState(false);
  const [currentHistoryItem, setCurrentHistoryItem] = useState<History | null>(
    null
  );

  const { deleteHistoryItem } = useApi();

  const formatDate = (date: number) => {
    const month = dayjs(date).get("month") + 1;
    const year = dayjs(date).get("year");
    if (month < 10) {
      return `0${month}.${year}`;
    }
    return `${month}.${year}`;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setCurrentHistoryItem(null);
  };

  return (
    <WorkHistoryMainContainer>
      <Title />
      <DividerAndIcon>
        <DividerMui />
        {preview && <AddIcon onClick={handleOpen} />}
      </DividerAndIcon>
      {workHistory?.map((item) => (
        <DateAndJobDescriptionContainer key={item._id}>
          <DateContainer>
            <p>
              {formatDate(item.startingDate)}-{" "}
              {item.endingDate === -1 ? "Present" : formatDate(item.endingDate)}
            </p>
          </DateContainer>
          <JobDescriptionContainer>
            <OccupationContainer>
              <OccupationHeader>
                <OccupationTitle>{item.title}</OccupationTitle>
                {preview && (
                  <EditAndDeleteContainer>
                    <EditIcon
                      onClick={() => {
                        setCurrentHistoryItem(item);
                        setOpen(true);
                      }}
                    />
                    <DeleteIcon
                      onClick={() => {
                        if (cvId) {
                          deleteHistoryItem(cvId, item._id).then(() => {
                            dispatch(removeCvHistoryItem(item._id));
                          });
                        }
                      }}
                    />
                  </EditAndDeleteContainer>
                )}
              </OccupationHeader>
              <p>{item.subTitle}</p>
            </OccupationContainer>
            <ResponsibilitiesContainer>
              <ResponsibilitiesTitle />
              {item.description?.map((item, index) => (
                <ul key={index}>
                  <li>{item}</li>
                </ul>
              ))}
            </ResponsibilitiesContainer>
            <TechnologiesContainer>
              <TechTit>
                Technologies:<span>{item.footer}</span>
              </TechTit>
            </TechnologiesContainer>
          </JobDescriptionContainer>
        </DateAndJobDescriptionContainer>
      ))}

      <HistoryFormModal
        open={open}
        type={HistoryType.WORK}
        values={currentHistoryItem}
        onCancel={handleCancel}
      />
    </WorkHistoryMainContainer>
  );
};
