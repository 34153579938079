import { Select } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../../../../style/colors";
import { SidebarSection } from "../SidebarSection/SidebarSection";

export const LanguagesSectionContainer = styled(SidebarSection)``;

export const AddIcon = styled.img.attrs({ src: "/images/AddButton.svg" })`
  width: 35px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 25px;
`;

export const LanguagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const LanguageBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;

  h2 {
    color: white;
    text-transform: capitalize;
  }
`;

export const LanguagePercentContainer = styled.div`
  width: 100%;
  height: 20px;
  box-shadow: -1px -1px 5px #006ec2, 1px 1px 5px #004387;
  border-radius: 67px;
  overflow: hidden;
  background-color: ${colors.blue300};
`;

export const LanguageBar = styled.div`
  width: ${(props) => props.itemProp};
  height: 100%;
  background-color: white;
`;

export const DeleteLanguageButton = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: rgb(255, 0, 0, 0.6);
  color: white;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  div {
    height: 3px;
    width: 8px;
    background: white;
  }
`;

export const ModalIputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const LanguagesInputBox = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  gap: 10px;
  overflow-y: auto;
  padding: 0px 20px;
`;

export const SelectInput = styled(Select)`
  root: {
  & .MuiFormLabel-root.Mui-disabled {
    color: red,
  },
},
  & .MuiOutlinedInput-notchedOutline {
    border-color: #57b3f1;
  }
  svg {
    color: ${colors.blue500};
  }
`;
