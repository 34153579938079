import {
  HeaderContainer,
  HeaderUtils,
  LogoContainer,
  ToggleButtonContainer,
  HeaderTitle,
  HeaderText,
  Logo,
  BigButtonToggle,
  SmallButtonToggle,
  LogoSmall,
  DeleteIcon,
} from "./HeaderStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleCollapsed,
  toggleDeleted,
} from "../../../../redux/sideBar/sidebar";
import { RootState } from "../../../../redux/store";

export const Header = () => {
  const dispatch = useDispatch();
  const toggle = useSelector((state: RootState) => state.sideBar.collapsed);

  return (
    <HeaderContainer isToggle={toggle}>
      <LogoContainer isToggle={toggle}>
        {toggle ? <Logo /> : <LogoSmall />}
        <ToggleButtonContainer>
          {toggle ? (
            <BigButtonToggle onClick={() => dispatch(toggleCollapsed())} />
          ) : (
            <SmallButtonToggle onClick={() => dispatch(toggleCollapsed())} />
          )}
        </ToggleButtonContainer>
      </LogoContainer>
      <HeaderUtils>
        {toggle && (
          <>
            <HeaderTitle>
              <HeaderText>My CVs list</HeaderText>
            </HeaderTitle>
            <DeleteIcon onClick={() => dispatch(toggleDeleted())} />
          </>
        )}
      </HeaderUtils>
    </HeaderContainer>
  );
};
