import { Checkbox, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCvHistoryItem,
  setCvHistoryItem,
} from "../../../../../../redux/cv/cv";
import { History, HistoryType } from "../../../../../../redux/cv/cvTypes";
import { RootState } from "../../../../../../redux/store";
import { MultiLineField } from "../../../../../muiComponents/InputField/MultiLineField";
import { TextField } from "../../../../../muiComponents/InputField/TextField";
import { TemplateModal } from "../../../../../muiComponents/TemplateModal/TemplateModal";
import {
  CheckBoxContainer,
  DateInput,
  DateInputContainer,
} from "./HistoryFormModalStyled";
import { useApi } from "../../../../../../providers/Api";

type Props = {
  type: HistoryType;
  values: History | null;
  open: boolean;
  onCancel: () => void;
};

const currentDate = new Date();

const initialFormState: Partial<History> = {
  startingDate: dayjs(currentDate).valueOf(),
  endingDate: dayjs(currentDate).valueOf(),
};

export const HistoryFormModal = ({ open, type, values, onCancel }: Props) => {
  const [formValues, setFormValues] = useState(initialFormState);
  const CvId = useSelector((state: RootState) => state.cv?._id);
  const dispatch = useDispatch();

  const [workCheckbox, setWorkCheckbox] = useState(false);
  const [educationCheckbox, setEducationCheckbox] = useState(false);

  const { addHistoryItem, updateHistoryItem } = useApi();

  const handleChangeTextField = (
    value: string | number | string[],
    name?: string
  ) => {
    if (name) {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleChangeDateField = (date: Dayjs | null, name: string) => {
    if (date) {
      handleChangeTextField(date.valueOf(), name);
    }
  };

  const handleChangeMultiline = (value: string[], name: string) => {
    if (value) {
      handleChangeTextField(value, name);
    }
  };

  const handleSave = () => {
    if (CvId) {
      if (formValues._id) {
        updateHistoryItem({
          cvId: CvId,
          type: type,
          _id: formValues._id,
          ...formValues,
        }).then((res) => {
          if (res) {
            dispatch(setCvHistoryItem(res));
            onCancel();
          }
        });
      } else {
        addHistoryItem({ cvId: CvId, type: type, ...formValues }).then(
          (res) => {
            if (res) {
              dispatch(createCvHistoryItem(res.historyItem));
              onCancel();
            }
          }
        );
      }
    }
  };

  useEffect(() => {
    if (open && values) {
      setFormValues(values);
    } else {
      setFormValues(initialFormState);
    }

    // eslint-disable-next-line
  }, [open]);

  return (
    <TemplateModal
      open={open}
      onCancel={onCancel}
      onSave={handleSave}
      title={type === "work" ? "Work History" : "Education"}
    >
      {type === "work" ? (
        <>
          <TextField
            label="Position Title"
            name="title"
            onChange={handleChangeTextField}
            value={formValues.title || ""}
          />
          <TextField
            label="Company"
            onChange={handleChangeTextField}
            name="subTitle"
            value={formValues.subTitle || ""}
          />
          <TextField
            label="Location"
            name="location"
            onChange={handleChangeTextField}
            value={formValues.location || ""}
          />
          <MultiLineField
            rows={3}
            label="Description"
            placeholder="Write your description, after each sentence press enter to create a bullet points format"
            onChange={handleChangeMultiline}
            name="description"
            value={formValues.description || []}
          />
          <DateInputContainer>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={1}>
                <DesktopDatePicker
                  label="Starting Date"
                  inputFormat="MM/DD/YYYY"
                  value={
                    formValues.startingDate
                      ? dayjs(formValues.startingDate)
                      : dayjs(new Date())
                  }
                  onChange={(date) => {
                    handleChangeDateField(date, "startingDate");
                  }}
                  renderInput={(params) => <DateInput {...params} />}
                />
              </Stack>
              <Stack spacing={1}>
                <DesktopDatePicker
                  label="Ending Date"
                  disabled={formValues.endingDate === -1}
                  inputFormat="MM/DD/YYYY"
                  value={
                    formValues.endingDate
                      ? dayjs(formValues.endingDate)
                      : dayjs(new Date())
                  }
                  onChange={(date) => {
                    handleChangeDateField(date, "endingDate");
                  }}
                  renderInput={(params) => <DateInput {...params} />}
                />
                <CheckBoxContainer>
                  <p>This is my current position</p>
                  <Checkbox
                    checked={formValues.endingDate === -1}
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        handleChangeTextField(-1, "endingDate");
                      } else {
                        handleChangeTextField(
                          dayjs(currentDate).valueOf(),
                          "endingDate"
                        );
                      }
                    }}
                  />
                </CheckBoxContainer>
              </Stack>
            </LocalizationProvider>
          </DateInputContainer>
          <MultiLineField
            rows={3}
            placeholder="Technologies, Example: React, Nextjs, Java ..."
            label="Technologies"
            name="footer"
            separator=","
            onChange={handleChangeMultiline}
            value={formValues.footer || []}
          />
        </>
      ) : (
        <>
          <TextField
            label="Specialization"
            name="title"
            onChange={handleChangeTextField}
            value={formValues.title || ""}
          />
          <TextField
            label="University"
            onChange={handleChangeTextField}
            name="subTitle"
            value={formValues.subTitle || ""}
          />
          <DateInputContainer>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={1}>
                <DesktopDatePicker
                  label="Starting Date"
                  inputFormat="MM/DD/YYYY"
                  value={
                    formValues.startingDate
                      ? dayjs(formValues.startingDate)
                      : dayjs(new Date())
                  }
                  onChange={(date) => {
                    handleChangeDateField(date, "startingDate");
                  }}
                  renderInput={(params) => <DateInput {...params} />}
                />
              </Stack>
              <Stack spacing={1}>
                <DesktopDatePicker
                  label="Ending Date"
                  inputFormat="MM/DD/YYYY"
                  disabled={formValues.endingDate === -1}
                  value={
                    formValues.endingDate
                      ? dayjs(formValues.endingDate)
                      : dayjs(new Date())
                  }
                  onChange={(date) => {
                    handleChangeDateField(date, "endingDate");
                  }}
                  renderInput={(params) => <DateInput {...params} />}
                />
                <CheckBoxContainer>
                  <p>I'm still a student</p>
                  <Checkbox
                    checked={formValues.endingDate === -1}
                    onChange={(e: any) => {
                      if (e.target.checked) {
                        handleChangeTextField(-1, "endingDate");
                      } else {
                        handleChangeTextField(
                          dayjs(currentDate).valueOf(),
                          "endingDate"
                        );
                      }
                    }}
                  />
                </CheckBoxContainer>
              </Stack>
            </LocalizationProvider>
          </DateInputContainer>
        </>
      )}
    </TemplateModal>
  );
};
