import { Snackbar } from "@mui/material";
import styled from "styled-components";
import { colors } from "../style/colors";

export const SnackbarComponent = styled(Snackbar)`
  .MuiPaper-root {
    background-color: ${colors.orange};
    height: 70px;
    font-size: 19px;
    justify-content: center;
  }
`;
