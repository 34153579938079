import styled from "styled-components";
import { colors } from "../../../../../style/colors";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  height: 390px;

  background-color: ${colors.paperGrey};
  border-radius: 5px;
  box-shadow: 24px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  align-items: center;
`;

export const CreateCV = styled.div`
  width: 200px;
  cursor: pointer;
`;

export const Input = styled(TextField)`
  width: 70%;

  color: red;
  border-color: ${colors.blue500};

  & label.Mui-focused {
    color: ${colors.blue500};
  }
  & label {
    color: ${colors.blue500};
  }
  & .MuiOutlinedInput-root {
    & input {
      color: ${colors.blue500};
    }
    & {
      color: ${colors.blue500};
    }
    & fieldset {
      border-color: ${colors.blue500};
    }
    &:hover fieldset {
      border-color: ${colors.blue500};
    }
    &.Mui-focused fieldset {
      border-color: ${colors.blue500};
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 67px;
  cursor: pointer;
`;

export const CancelButton = styled(Button)`
  background-color: white;
  border: 2px solid ${colors.blue500};
  color: ${colors.blue500};
`;
export const CreateButton = styled(Button)`
  background-color: ${colors.blue300};
  border: 2px solid ${colors.blue300};
  color: white;
`;
