import { StyledInput } from "./InputFieldStyled";

type Props = {
  label: string;
  required?: boolean;
  value: string;
  name: string;
  "data-testid"?: string;
  onChange: (value: string, name?: string) => void;
  multiline?: boolean;
};

export const TextField = (props: Props) => {
  return (
    <StyledInput
      {...props}
      fullWidth
      type="text"
      variant="outlined"
      onChange={(e) => {
        props.onChange(e.currentTarget.value, e.currentTarget.name);
      }}
    />
  );
};
