import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0px;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
 }


/* @media print {
  .hide-on-print {
    display: none;
  }
  .distance-on-print {
    margin-bottom: 40px;
  }
  .workHistory-bottom-distance {
    margin-bottom: 500px;
  }
  .delete_bottom {
    margin-bottom: 0px;
  }
  .boxShadow_onPrint {
    box-shadow: none;
  }
} */

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
   
  }
}
  


  body {
    font-family: 'Montserrat', sans-serif;
  }
`;
