import {
  LoginApiPayload,
  GetCVsResponse,
  CreateNewCvResponse,
  UpdateContactApiPayload,
  UpdateNameAndPositionApiPayload,
  UpdateDescriptionApiPayload,
  addHistoryItemApiType,
  UpdateLanguagesApiPayload,
  UpdateSkillsApiPayload,
  RegisterApiPayload,
  updateHistoryItemApiPayload,
  AddHistoryItemResponse,
  User,
} from "./types";
import axiosLib from "axios";
import { Cv, History, InterestsType } from "../redux/cv/cvTypes";

const token = localStorage.getItem("token");
const axios = axiosLib.create({
  baseURL: process.env.REACT_APP_PRODUCTION_API,
});
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

export const getImagesAWS = (type: string): Promise<any> => {
  return axios
    .get(`/aws/imagesData?type=${type}`, config)
    .then((res: any) => res.data);
};

export const uploadCustomInterestsImage = (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);

  return axios
    .post("/aws/data", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data;",
      },
    })
    .then((res) => res.data);
};

// AVATAR
export const updateCvAvatarApi = (file: File, _id: string): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("_id", _id);

  return axios
    .post("/aws/cv-avatar", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data;",
      },
    })
    .then((res) => res.data);
};

export const getUserByEmail = (): Promise<User> => {
  return axios.get("/user/me", config).then((res) => res.data);
};

export const loginApi = (payload: LoginApiPayload): Promise<void> => {
  return axios
    .post<{ access_token: string }>("/auth/login", payload)
    .then(({ data: { access_token } }) => {
      localStorage.setItem("token", access_token);
      window.location.replace("/");
    });
};

export const registerApi = (payload: RegisterApiPayload) => {
  return axios.post("/user", payload, config);
};

export const getCVsApi = (): Promise<GetCVsResponse> => {
  return axios.get("/cv", config).then((res) => {
    return res.data;
  });
};

export const deleteCvById = (id: string | undefined): Promise<void> => {
  return axios
    .delete(`/cv/${id}`, config)
    .then((data) => console.log(data))
    .catch((e) => console.log(e.message));
};

export const getCVById = (id: string): Promise<Cv> => {
  return axios.get(`/cv/${id}`, config).then((res) => {
    return res.data;
  });
};

export const createNewCVApi = (
  firstName: string,
  lastName: string,
  position: string
): Promise<CreateNewCvResponse> => {
  return axios
    .post(
      "/cv",
      {
        firstName,
        lastName,
        position,
      },
      config
    )
    .then(({ data }) => data);
};

export const deleteCVsApi = (ids: string[]): Promise<void> => {
  return axios.post("/cv/delete", ids, config).then((res) => res.data);
};

// Contact
export const updateCvContact = (
  payload: UpdateContactApiPayload
): Promise<void> => {
  return axios
    .patch("/cv/details/contact", payload, config)
    .then((res) => res.data);
};

export const deleteCvContact = (cvId: string): Promise<void> => {
  return axios
    .delete(`/cv/details/contact?cvId=${cvId}`, config)
    .then((res) => res.data);
};

// Name and Position
export const updateCvNameAndPosition = (
  payload: UpdateNameAndPositionApiPayload
): Promise<void> => {
  return axios
    .patch("/cv/details/name", payload, config)
    .then((res) => res.data);
};

// Languages

export const updateCvLanguages = (
  payload: UpdateLanguagesApiPayload
): Promise<void> => {
  return axios
    .patch("/cv/details/languages", payload, config)
    .then((res) => res.data);
};

export const deleteCvLanguages = (cvId: string): Promise<void> => {
  return axios
    .delete(`/cv/details/languages?cvId=${cvId}`, config)
    .then((res) => res.data);
};

// Skills

export const updateCvSkills = (
  payload: UpdateSkillsApiPayload
): Promise<void> => {
  return axios
    .patch("/cv/details/skills", payload, config)
    .then((res) => res.data);
};

export const deleteCvSkills = (cvId: string): Promise<void> => {
  return axios
    .delete(`/cv/details/skills?cvId=${cvId}`, config)
    .then((res) => res.data);
};

// Interests

export const UpdateCvInterests = (payload: {
  id: string;
  interests: InterestsType[];
}): Promise<void> => {
  return axios
    .patch("cv/details/interests", payload, config)
    .then((res) => res.data);
};

export const deleteCvInterests = (cvId: string): Promise<void> => {
  return axios
    .delete(`cv/details/interests?cvId=${cvId}`, config)
    .then((data) => console.log(data));
};

// Description
export const updateCvDescription = (
  payload: UpdateDescriptionApiPayload
): Promise<void> => {
  return axios.patch("/cv/details/description", payload, config);
};

// History
export const addHistoryItemApi = (
  payload: addHistoryItemApiType
): Promise<AddHistoryItemResponse> => {
  return axios
    .put("/cv/history/item", payload, config)
    .then((data) => {
      return data;
    })
    .then((res) => res.data);
};

export const updateHistoryItemApi = (
  payload: updateHistoryItemApiPayload
): Promise<History> => {
  return axios
    .patch("/cv/history/item", payload, config)
    .then((data) => {
      return data;
    })
    .then((res) => res.data);
};

export const deleteHistoryItemApi = (
  cvId: string,
  _id: string
): Promise<void> => {
  return axios.delete(`/cv/history/item?cvId=${cvId}&id=${_id}`, config);
};
