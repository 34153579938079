import {
  NameAndPositionContainer,
  NameAndPositionText,
  EditIcon,
  NameSurname,
  Occupation,
  Input,
} from "./NameAndPositionStyled";
import { ChangeEvent, useEffect, useState } from "react";
import { TemplateModal } from "../../../../muiComponents/TemplateModal/TemplateModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { NameAndPositionType } from "../../../../../redux/cv/cvTypes";
import { setCvTitle } from "../../../../../redux/cv/cv";
import { usePreviewContext } from "../../../../../context/PreviewPDFContext";
import { useApi } from "../../../../../providers/Api";

type FormErrors = Partial<NameAndPositionType>;

export const NameAndPosition = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, position, _id } =
    useSelector((state: RootState) => state.cv) || {};

  const preview = usePreviewContext();
  const { updateNameAndPosition } = useApi();

  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState<NameAndPositionType>({
    firstName: firstName || "",
    lastName: lastName || "",
    position: position || "",
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: undefined });
  };

  const handleSave = () => {
    if (_id) {
      updateNameAndPosition({ id: _id, ...formValues }).then(() => {
        dispatch(setCvTitle(formValues));
        handleCancel();
      });
    }
  };

  useEffect(() => {
    setFormValues({
      firstName: firstName || "",
      lastName: lastName || "",
      position: position || "",
    });
    setFormErrors({});
    setOpen(false);
    // eslint-disable-next-line
  }, [_id]);

  return (
    <NameAndPositionContainer>
      <NameAndPositionText>
        <NameSurname>
          {firstName} {lastName}
        </NameSurname>
        <Occupation>{position}</Occupation>
      </NameAndPositionText>
      {preview && <EditIcon onClick={handleOpen} />}
      <TemplateModal
        open={open}
        onCancel={handleCancel}
        onSave={handleSave}
        title="Name & Position"
      >
        <Input
          required
          onChange={handleChange}
          variant="outlined"
          label="First name"
          type="text"
          name="firstName"
          value={formValues.firstName}
        />
        <Input
          required
          onChange={handleChange}
          variant="outlined"
          label="Last name"
          type="text"
          name="lastName"
          value={formValues.lastName}
        />
        <Input
          required
          onChange={handleChange}
          variant="outlined"
          label="Position"
          type="text"
          name="position"
          value={formValues.position}
        />
      </TemplateModal>
    </NameAndPositionContainer>
  );
};
