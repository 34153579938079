import { ChangeEvent, FormEvent, useState } from "react";
import { LoginButton } from "../muiComponents/LoginButton";
import { InputField } from "../muiComponents/InputField/InputField";
import { isEmpty } from "lodash";
import { FormTitle, LoginLinkMessage } from "./LoginFormStyles";
import { Form } from "../LoginForm/LoginFormStyles";
import { ErrorText } from "../LoginForm/LoginFormStyles";
import { LoadingBar } from "../LoginForm/LoginFormStyles";
import { loginApi, registerApi } from "../../api/index";

type FormValues = {
  username: string;
  password: string;
};

type FormErrors = Partial<{
  form: string;
  username: string;
  password: string;
  msg: string;
}>;

const LoginForm = () => {
  const initialValues: FormValues = {
    username: "",
    password: "",
  };

  const [logState, setLogState] = useState(false);
  const handleLogState = () => {
    setLogState(!logState);
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [disable, setDisable] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: undefined });
  };

  const validate = (values: FormValues) => {
    const errors: FormErrors = {};
    if (!values.username) {
      errors.username = "Username is required!";
    }

    if (!values.password) {
      errors.password = "Password is required!";
    }

    return errors;
  };

  const handleSubmitLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validate(formValues);
    if (isEmpty(errors)) {
      loginApi({
        email: formValues.username,
        password: formValues.password,
      }).catch((error) => {
        setDisable(false);
        setFormErrors({
          form: error.response.data.message,
        });
      });
    } else {
      setFormErrors(errors);
    }
  };

  const handleSubmitRegister = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validate(formValues);
    if (isEmpty(errors)) {
      registerApi({
        email: formValues.username,
        password: formValues.password,
      })
        .then((res) => {
          if (res.status === 201) {
            setFormValues({ username: "", password: "" });
            handleLogState();
          }
        })
        .catch((error) => {
          setDisable(false);
          setFormErrors({
            form: error.response.data.message,
          });
        });
    } else {
      setFormErrors(errors);
    }
  };

  const triggerAnimation = () => {
    if (logState) {
      return "login";
    }
  };

  return (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={logState ? handleSubmitRegister : handleSubmitLogin}
      name="form"
      itemProp={triggerAnimation()}
    >
      {logState ? (
        <FormTitle>Register</FormTitle>
      ) : (
        <FormTitle>Log In</FormTitle>
      )}

      <InputField
        required
        onChange={handleChange}
        variant="outlined"
        label="Email"
        type="text"
        name="username"
        value={formValues.username}
        dataTestid="user-input"
      />
      <ErrorText>{formErrors.username}</ErrorText>
      <InputField
        required
        onChange={handleChange}
        type="password"
        name="password"
        variant="outlined"
        label="Password"
        value={formValues.password}
        dataTestid="password-input"
      />
      <ErrorText>{formErrors.password}</ErrorText>
      <ErrorText>{formErrors.form}</ErrorText>
      {logState ? (
        <LoginButton>
          <p>Create</p>
        </LoginButton>
      ) : (
        <LoginButton>
          <p>Log In</p>
        </LoginButton>
      )}

      {disable ? <LoadingBar /> : ""}
      {logState ? (
        <LoginLinkMessage onClick={handleLogState}>
          Already have an account? Click here
        </LoginLinkMessage>
      ) : (
        <LoginLinkMessage onClick={handleLogState}>
          Didn't have an account? Click here
        </LoginLinkMessage>
      )}
    </Form>
  );
};

export default LoginForm;
