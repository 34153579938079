import { useCallback, useState } from "react";

type TrackPromise = <T>(promise: Promise<T>) => Promise<T>;

export const useTrackPromise = () => {
  const [promiseInProgress, setPromiseInProgress] = useState(false);
  const [promiseError, setPromiseError] = useState(false);

  const trackPromise: TrackPromise = useCallback((promise) => {
    setPromiseInProgress(true);

    return promise
      .then((data: any) => {
        setPromiseInProgress(false);
        return data;
      })
      .catch((e) => {
        setPromiseError(true);
        throw new Error(e.message);
      })
      .finally(() => {
        setPromiseInProgress(false);
      });
  }, []);

  const resetPromiseError = useCallback(() => {
    setPromiseError(false);
  }, []);

  return { promiseInProgress, promiseError, trackPromise, resetPromiseError };
};
