import { Divider, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../../../../style/colors";

export const DividerAndIcon = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  gap: 10px;
`;

export const SectionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
`;

export const AddIcon = styled.img.attrs({ src: "/images/AddButton.svg" })`
  width: 35px;
  cursor: pointer;
`;

export const EditIcon = styled.img.attrs({ src: "/images/EditIcon.svg" })`
  width: 30px;
  cursor: pointer;
`;

export const DeleteIcon = styled.img.attrs({ src: "/images/DeleteIcon.svg" })`
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
`;

export const DividerMui = styled(Divider)`
  width: 160px;
  background-color: ${colors.blue500};
  margin-bottom: 16px;
  margin-left: 16px;
  flex: 1;
`;

export const Title = styled(Typography).attrs({
  variant: "h5",
})`
  color: white;
  font-weight: bold;
`;
