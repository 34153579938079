import { TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../../../../style/colors";

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* position: relative;
  page-break-after: always; */
`;

export const TitleAndButtonContainer = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-size: 25px;
  }
`;

export const Title = styled(Typography).attrs({
  variant: "h5",
  children: "Description",
})``;

export const EditIcon = styled.img.attrs({ src: "/images/EditIcon.svg" })`
  width: 30px;
  cursor: pointer;
`;

export const DescriptionTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Input = styled(TextField).attrs({
  multiline: true,
  rows: "10",
})`
  &.MuiTextField-root {
    width: 500px;
    height: 356px;
    margin-top: 20px;
    margin-bottom: -50px;
    label {
      color: #57b3f1;
    }
    fieldset {
      border-color: #57b3f1;
    }
    input {
      color: #57b3f1;
    }
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #57b3f1;
    }
  }
`;
