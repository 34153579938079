import { TemplateModal } from "../../../../muiComponents/TemplateModal/TemplateModal";
import {
  AddIcon,
  AddIconPreview,
  AddMoreHobbiesContainer,
  ExtraModalAddContainer,
  InterestImageContainer,
  InterestImageWithTextContainer,
  InterestsImagesContainer,
  InterestsSectionContainer,
  InterestSectionBox,
  ExtraInterestModalText,
  ExtraInputContainer,
  ErrorSizeMessage,
  FirstModalTitle,
} from "./InterestsSectionStyled";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { InterestsType } from "../../../../../redux/cv/cvTypes";
import { setCvInterests } from "../../../../../redux/cv/cv";
import { checkEmptyArray } from "../../../../../utils";
import { TextField } from "../../../../muiComponents/InputField/TextField";
import { trackPromise } from "react-promise-tracker";
import { useApi } from "../../../../../providers/Api";
import { getImagesAWS, uploadCustomInterestsImage } from "../../../../../api";

export const InterestsSection = () => {
  const { _id, interests } = useSelector((state: RootState) => state.cv) || {};
  const dispatch = useDispatch();

  const { updateInterests, deleteInterests } = useApi();

  // First Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (interests) {
      setInterestList(interests);
    }
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  //

  // Extra MOdal
  const [extraModalValue, setExtraModalValue] = useState("");
  const [openExtraModal, setOpenExtraModal] = useState(false);
  const [userInterestPhoto, setUserInterestPhoto] = useState<File>();
  const [imageSizeError, setImageSizeError] = useState("");

  const handleChange = (value: string) => {
    setExtraModalValue(value);
  };

  const handleOpenExtraModal = () => {
    setOpenExtraModal(true);
  };
  const handleCancelExtraModal = () => {
    setOpenExtraModal(false);
  };

  //-- Create firebase functionality for the extra Modal

  const onUploadImage = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files[0].size < 4000000) {
      setImageSizeError("");
      const file = target.files[0];
      setUserInterestPhoto(file);
    } else {
      setImageSizeError("File size is too large");
    }
  };

  //--
  const handleSaveExtraModal = () => {
    if (userInterestPhoto) {
      const newFile = new File([userInterestPhoto], extraModalValue, {
        type: userInterestPhoto.type,
      });
      trackPromise(
        uploadCustomInterestsImage(newFile).then(() => {
          handleCancelExtraModal();
        })
      );
    }
  };

  //

  // :::::: Create default values for interests
  const [interestList, setInterestList] = useState<InterestsType[]>([]);

  const checkSelectedHobbies = (hobbyName: string) => {
    if (
      interestList.filter((interest) => interest.hobbyName === hobbyName)
        .length > 0
    ) {
      return true;
    } else {
      return !!interestList.find(
        (interest) => interest.hobbyName === hobbyName
      );
    }
  };

  const selectImage = (selection: InterestsType) => {
    if (
      interestList.find(
        (interest) => interest.hobbyName === selection.hobbyName
      )
    ) {
      setInterestList(
        interestList.filter(
          (interest) => interest.hobbyName !== selection.hobbyName
        )
      );
    } else if (interestList.length < 6) {
      setInterestList([...interestList, selection]);
    }
  };

  // GET ALL INTERESTS FROM FIREBASE

  const [imageUrls, setImageUrls] = useState<InterestsType[]>([]);

  useEffect(() => {
    trackPromise(
      getImagesAWS("interests").then((res) => {
        const filteredData = res;
        const imageUrls = filteredData.map((item: any) => item.url);
        const imageNames = filteredData.map((item: any) => item.name);
        const interestsData = filteredData.map((item: any, index: number) => ({
          avatar: imageUrls[index],
          hobbyName: imageNames[index],
        }));
        setImageUrls(interestsData);
      })
    );
    // eslint-disable-next-line
  }, [openExtraModal]);

  const handleSave = () => {
    if (_id) {
      updateInterests({ id: _id, interests: interestList }).then(() => {
        dispatch(setCvInterests(interestList));
        handleCancel();
      });
    }
  };
  const handleDelete = () => {
    if (_id) {
      deleteInterests(_id).then(() => {
        dispatch(setCvInterests([]));
      });
    }
  };

  return (
    <>
      <InterestsSectionContainer
        handleOpen={handleOpen}
        title="Interest"
        handleDelete={handleDelete}
        isEditable={checkEmptyArray(interests)}
      >
        <InterestSectionBox>
          {interests?.map((item, index) => (
            <img key={index} src={item.avatar} alt={item.avatar}></img>
          ))}
        </InterestSectionBox>
      </InterestsSectionContainer>
      <TemplateModal open={open} onCancel={handleCancel} onSave={handleSave}>
        <FirstModalTitle>
          Choose your intrests <span>{"(maximum of 6 items)"}</span>
        </FirstModalTitle>
        <InterestsImagesContainer>
          {imageUrls.map(({ hobbyName, avatar }, index) => {
            return (
              <InterestImageWithTextContainer
                key={index}
                checkSelected={checkSelectedHobbies(hobbyName)}
              >
                <img
                  src={avatar}
                  onClick={() => {
                    selectImage({ avatar, hobbyName });
                  }}
                  alt={avatar}
                />
                <p>{hobbyName}</p>
              </InterestImageWithTextContainer>
            );
          })}
        </InterestsImagesContainer>
        <AddMoreHobbiesContainer>
          <AddIcon onClick={handleOpenExtraModal} />
          <p>Add images only in PNG format.</p>
          <TemplateModal
            open={openExtraModal}
            onCancel={handleCancelExtraModal}
            onSave={handleSaveExtraModal}
          >
            <ExtraInterestModalText>
              <h1>Add new interest</h1>
              <p>Please add images only in PNG format.</p>
              <p>Your interests should have a maximum of 2 words</p>
              <p>ex: Video Games, Watching Movies</p>
            </ExtraInterestModalText>
            <ExtraModalAddContainer>
              <ExtraInputContainer>
                <InterestImageContainer>
                  <label htmlFor="thefile">
                    <input
                      type="file"
                      accept="image/png"
                      id="thefile"
                      name="thefile"
                      onChange={onUploadImage}
                    />
                    <AddIconPreview
                      src={
                        userInterestPhoto
                          ? URL.createObjectURL(userInterestPhoto)
                          : "/images/uploadSVG.svg"
                      }
                      alt="something"
                    />
                  </label>
                </InterestImageContainer>
                <TextField
                  label="Interest name"
                  name="interest"
                  onChange={handleChange}
                  value={extraModalValue}
                />
              </ExtraInputContainer>
              <ErrorSizeMessage>
                <p>{imageSizeError}</p>
              </ErrorSizeMessage>
            </ExtraModalAddContainer>
          </TemplateModal>
        </AddMoreHobbiesContainer>
      </TemplateModal>
    </>
  );
};
