import { LinearProgress } from "@mui/material";
import styled from "styled-components";

export const DashboardInterfaceContainer = styled.div`
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const LoadingBar = styled(LinearProgress)`
  border-radius: 10px;
  width: 100%;
  height: 0.4%;
  background-color: yellow;
`;

export const DashboardComponents = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
`;

export const DashboardHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  margin-bottom: 15px;
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
