import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  CloseImage,
  FirebaseHeaderDivider,
  FirebaseImagesContainer,
  FirebaseImagesHeader,
} from "./SkillsImageModalStyled";
import { ModalTitle } from "../../../../muiComponents/TemplateModal/TemplateModalStyled";
import { trackPromise } from "react-promise-tracker";
import { getImagesAWS } from "../../../../../api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  p: 4,
  overflow: "scroll",
};

type Props = {
  selectImage: (url: string) => void;
  handleClose: () => void;
  open: boolean;
};

export const SkillsImageModal = ({ selectImage, handleClose, open }: Props) => {
  // GET ALL
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    trackPromise(
      getImagesAWS("skills").then((res) => {
        const filteredData = res;
        const imageUrls = filteredData.map((item: any) => item.url);
        setImageUrls(imageUrls);
      })
    );
    // eslint-disable-next-line
  }, [setImageUrls]);

  //
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FirebaseImagesHeader>
          <ModalTitle>Choose your skill</ModalTitle>
          <CloseImage src="/images/Close.svg" onClick={handleClose} />
        </FirebaseImagesHeader>
        <FirebaseHeaderDivider src="/images/universalDivider.svg" />
        <FirebaseImagesContainer>
          {imageUrls.map((url, index) => {
            return (
              <img
                src={url}
                key={index}
                onClick={() => selectImage(url)}
                alt={url}
              />
            );
          })}
        </FirebaseImagesContainer>
      </Box>
    </Modal>
  );
};
