import styled from "styled-components";

export const HeaderContainer = styled.div<{ isToggle: boolean }>`
  height: 20%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (!props.isToggle ? "center" : "none")};
`;

export const LogoContainer = styled.div<{ isToggle: boolean }>`
  height: 50%;
  display: flex;
  margin-right: ${(props) => (!props.isToggle ? "45px" : "none")};
`;

export const Logo = styled.img.attrs({ src: "/images/SimpleWhite.svg" })`
  width: 119px;
  margin-left: 35px;
`;
export const LogoSmall = styled.img.attrs({ src: "/images/SimpleWhite.svg" })`
  width: 70px;
  margin-left: 35px;
`;

export const DeleteIcon = styled.img.attrs({ src: "/images/DeleteIcon.svg" })`
  width: 30px;
  margin-left: 30px;
  cursor: pointer;
`;

export const BigButtonToggle = styled.img.attrs({
  src: "/images/ListToggle.svg",
})`
  height: 93px;
  width: 119px;
  margin-left: 35px;
`;
export const SmallButtonToggle = styled.img.attrs({
  src: "/images/ListToggleSmall.svg",
})`
  height: 40px;
  width: 119px;
  left: -80px;
  top: 20px;
  position: absolute;
`;

export const HeaderUtils = styled.div`
  height: 50%;
  display: flex;
`;

export const ToggleButtonContainer = styled.div`
  position: absolute;
  right: -16px;
  cursor: pointer;
`;

export const ButtonToggleOn = styled.button`
  height: 36px;
  width: 42px;
  margin-top: 30px;
  border-radius: 40px;
  background-color: #57b3f1;
  border: none;
`;

export const HeaderTitle = styled.div`
  width: 70%;
  color: white;
  display: flex;
  align-items: center;
`;

export const HeaderText = styled.p`
  margin-left: 41px;
  font-size: 24px;
  font-weight: bolder;
`;
