import { TextFieldProps } from "@mui/material";
import { ChangeEvent } from "react";
import { StyledInput } from "../InputField/InputFieldStyled";

type Props = {
  label: string;
  variant: TextFieldProps["variant"];
  required?: boolean;
  type: string;
  value: string;
  name: string;
  dataTestid?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const InputField = ({
  label,
  variant,
  required,
  onChange,
  type,
  value,
  name,
  dataTestid,
}: Props) => {
  return (
    <StyledInput
      required={required}
      onChange={onChange}
      label={label}
      variant={variant}
      type={type}
      value={value}
      name={name}
      data-testid={dataTestid}
      fullWidth
    />
  );
};
