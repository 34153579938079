import { TextField } from "@mui/material";
import styled from "styled-components";
import { SidebarSection } from "../SidebarSection/SidebarSection";

export const ContactSectionContainer = styled(SidebarSection)``;

export const Input = styled(TextField)`
  &.MuiTextField-root {
    width: 300px;
    height: 56px;
    margin-top: 0px;
    margin-bottom: 10px;
    label {
      color: #57b3f1;
    }
    fieldset {
      border-color: #57b3f1;
    }
    input {
      color: #57b3f1;
    }
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #57b3f1;
    }
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-break: anywhere;

  span {
    color: white;
    font-size: 16px;
  }
`;

export const LocationLogo = styled.img.attrs({ src: "/images/Location.svg" })`
  width: 25px;
`;
export const PhoneLogo = styled.img.attrs({ src: "/images/Phone.svg" })`
  width: 25px;
`;

export const EmailLogo = styled.img.attrs({ src: "/images/Mail.svg" })`
  width: 25px;
`;

export const LinkedIn = styled.img.attrs({ src: "/images/LinkedIn.svg" })`
  width: 25px;
`;
