import React from "react";
import { CreateNewCVContainer } from "./DashboardCreateNewStyles";
import BasicModal from "./NewCVModal/NewCVModal";

export const DashboardCreateNew = () => {
  return (
    <CreateNewCVContainer>
      <BasicModal />
    </CreateNewCVContainer>
  );
};
