import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ContactSectionContainer,
  DetailsContainer,
  EmailLogo,
  LinkedIn,
  LocationLogo,
  PhoneLogo,
} from "./ContactSectionStyled";
import { TemplateModal } from "../../../../muiComponents/TemplateModal/TemplateModal";
import { Contact } from "../../../../../redux/cv/cvTypes";
import { RootState } from "../../../../../redux/store";
import { Typography } from "@mui/material";
import { hasAtleastOneValue } from "../../../../../utils";
import { setCvContact } from "../../../../../redux/cv/cv";
import { TextField } from "../../../../muiComponents/InputField/TextField";
import { useApi } from "../../../../../providers/Api";

type FormErrors = Partial<Contact>;

// to treat form errors
export const ContactSection = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const contact = useSelector((state: RootState) => state.cv?.contact);
  const { _id } = useSelector((state: RootState) => state.cv) || {};

  const { updateContact, deleteContact } = useApi();

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [formValues, setFormValues] = useState<Contact>({
    city: contact?.city || "",
    county: contact?.county || "",
    mobilePhone: contact?.mobilePhone || "",
    email: contact?.email || "",
    linkedIn: contact?.linkedIn || "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange = (value: string, name?: string) => {
    if (name) {
      setFormValues({ ...formValues, [name]: value });
      setFormErrors({ ...formErrors, [name]: undefined });
    }
  };

  const handleSave = () => {
    if (_id) {
      updateContact({ id: _id, ...formValues }).then(() => {
        dispatch(setCvContact(formValues));
        handleCancel();
      });
    }
  };

  const handleDelete = () => {
    if (_id) {
      deleteContact(_id).then(() => {
        dispatch(
          setCvContact({
            city: "",
            county: "",
            mobilePhone: "",
            email: "",
            linkedIn: "",
          })
        );
      });
    }
  };

  useEffect(() => {
    setFormValues({
      city: contact?.city || "",
      county: contact?.county || "",
      mobilePhone: contact?.mobilePhone || "",
      email: contact?.email || "",
      linkedIn: contact?.linkedIn || "",
    });
    setFormErrors({});
    setOpen(false);
    // eslint-disable-next-line
  }, [_id]);

  return (
    <>
      <ContactSectionContainer
        handleOpen={handleOpen}
        handleDelete={handleDelete}
        isEditable={hasAtleastOneValue(formValues)}
        title="Contact"
      >
        <DetailsContainer>
          <LocationLogo />
          <span>
            {contact?.city}, {contact?.county}
          </span>
        </DetailsContainer>
        <DetailsContainer>
          <PhoneLogo />
          <span>{contact?.mobilePhone}</span>
        </DetailsContainer>
        <DetailsContainer>
          <EmailLogo />
          <span>{contact?.email}</span>
        </DetailsContainer>
        <DetailsContainer>
          <LinkedIn />
          <span>{contact?.linkedIn}</span>
        </DetailsContainer>
      </ContactSectionContainer>
      <TemplateModal
        key="test"
        open={open}
        onCancel={handleCancel}
        onSave={handleSave}
      >
        <Typography variant="h5">Address</Typography>
        <TextField
          label="City"
          name="city"
          value={formValues.city}
          onChange={handleChange}
        />
        <TextField
          label="County"
          name="county"
          value={formValues.county}
          onChange={handleChange}
        />
        <Typography variant="h5">Contact Details</Typography>
        <TextField
          label="Mobile Phone"
          name="mobilePhone"
          value={formValues.mobilePhone}
          onChange={handleChange}
        />
        <TextField
          label="Email"
          name="email"
          value={formValues.email}
          onChange={handleChange}
        />
        <TextField
          label="LinkedIn Profile Name"
          name="linkedIn"
          value={formValues.linkedIn}
          onChange={handleChange}
        />
      </TemplateModal>
    </>
  );
};
