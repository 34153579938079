export const hasLoginToken = () => {
  return !!localStorage.getItem("token");
};

export const hasAtleastOneValue = (obj: { [key: string]: string }) => {
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (value) {
      return true;
    }
  }
  return false;
};

export const isEmptyObject = (obj: { [key: string]: string[] }) => {
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (value.length !== 0) {
      return true;
    }
  }
  return false;
};

export const checkEmptyArray = (array: Object[] | undefined) => {
  if (array) {
    if (array.length > 0) {
      return true;
    } else {
      return false;
    }
  }
};
