import React, { useEffect } from "react";
import { TemplateModal } from "../../../../muiComponents/TemplateModal/TemplateModal";

import {
  DescriptionContainer,
  DescriptionTextContainer,
  EditIcon,
  Title,
  TitleAndButtonContainer,
} from "./DescriptionSectionStyled";
import { useState } from "react";
import { Description } from "../../../../../redux/cv/cvTypes";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setCvDescription } from "../../../../../redux/cv/cv";
import { MultiLineField } from "../../../../muiComponents/InputField/MultiLineField";
import { usePreviewContext } from "../../../../../context/PreviewPDFContext";
import { useApi } from "../../../../../providers/Api";

export const DescriptionSection = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { description, _id } =
    useSelector((state: RootState) => state.cv) || {};

  const preview = usePreviewContext();
  const { updateDescription } = useApi();

  const [inputValues, setInputValues] = useState<Description>(
    description || []
  );

  const handleChange = (value: string[]) => {
    setInputValues(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (_id) {
      updateDescription({ id: _id, description: inputValues }).then(() => {
        dispatch(setCvDescription(inputValues));
        handleCancel();
      });
    }
  };

  useEffect(() => {
    setInputValues(description || []);
    setOpen(false);

    // eslint-disable-next-line
  }, [_id]);

  return (
    <DescriptionContainer>
      <TitleAndButtonContainer>
        <Title />
        {preview && <EditIcon onClick={handleOpen} />}
      </TitleAndButtonContainer>
      <DescriptionTextContainer>
        {description?.map((item, index) => (
          <ul key={index}>
            <li>{item}</li>
          </ul>
        ))}
      </DescriptionTextContainer>
      <TemplateModal
        open={open}
        onCancel={handleCancel}
        onSave={handleSave}
        title="Description"
      >
        <MultiLineField
          rows={10}
          label="Description"
          placeholder="Write your description"
          onChange={handleChange}
          name="description"
          value={inputValues}
        />
      </TemplateModal>
    </DescriptionContainer>
  );
};
