import { Divider, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../../../../../style/colors";

export const EducationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  /* padding: 10px; */
`;

export const Title = styled(Typography).attrs({
  variant: "h5",
  children: "Education",
})``;

export const DividerAndIcon = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  gap: 10px;
  width: 100%;
`;

export const AddIcon = styled.img.attrs({ src: "/images/AddButton.svg" })`
  width: 35px;
  cursor: pointer;
`;

export const EditIcon = styled.img.attrs({ src: "/images/EditIcon.svg" })`
  width: 30px;
  cursor: pointer;
`;

export const DeleteIcon = styled.img.attrs({ src: "/images/DeleteIcon.svg" })`
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
`;

export const DividerMui = styled(Divider)`
  background-color: ${colors.blue500};
  margin-bottom: 16px;
  margin-left: 16px;
  flex: 1;
`;

export const DateAndEducationContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

export const DateContainer = styled.div`
  width: 20%;
  p {
    margin-top: 10px;
    text-align: center;
    color: ${colors.blue500};
  }
`;

export const EducationDescriptionContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;

  h6 {
    font-size: 20px;
  }
`;

export const EducationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EducationTitle = styled(Typography).attrs({
  variant: "h6",
})`
  color: ${colors.blue500};
  margin-right: auto;
`;

export const EducationHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditAndDeleteContainer = styled.div`
  display: flex;
`;
