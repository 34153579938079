import { TextField } from "./TextField";

type Props = {
  label: string;
  required?: boolean;
  value: string[];
  name: string;
  dataTestid?: string;
  rows: number;
  onChange: (value: string[], name: string) => void;
  separator?: string;
  placeholder: string;
};

export const MultiLineField = (props: Props) => {
  return (
    <TextField
      {...props}
      multiline
      value={props.value.join(props.separator ?? "\n")}
      onChange={(value) => {
        props.onChange(
          value.split(props.separator ? /[\n\r\s]+/g : /[\n\r]+/g),
          props.name
        );
      }}
    />
  );
};
