import React, { useState, useContext, useCallback, createContext } from "react";

export const Context = createContext({
  preview: false,
  togglePreview: () => {},
  showPreview: () => {},
  hidePreview: () => {},
});

type Props = {
  children: React.ReactNode;
};
export const PreviewPDFProvider = ({ children }: Props) => {
  const [preview, setPreview] = useState(true);

  const showPreview = useCallback(() => {
    setPreview(true);
  }, []);

  const hidePreview = useCallback(() => {
    setPreview(false);
  }, []);

  const togglePreview = useCallback(() => {
    setPreview(!preview);
  }, [preview]);

  return (
    <Context.Provider
      value={{ preview, togglePreview, showPreview, hidePreview }}
    >
      {children}
    </Context.Provider>
  );
};

export const usePreviewContext = () => {
  const { preview } = useContext(Context);
  return preview;
};

export const useSetPreviewContext = () => {
  return useContext(Context);
};
