export type SetCVPending = {
  type: string;
  payload: boolean;
};

export type CvsState = {
  pending: boolean;
  success?: null | boolean;
  data: [];
};

export type CvItem = {
  _id: string;
  firstName: string;
  lastName: string;
  avatar?: Avatar;
};

export type Avatar = {
  url: string;
  name: string;
};

export type Contact = {
  city: string;
  county: string;
  mobilePhone: string;
  email: string;
  linkedIn: string;
};

export type InterestsType = {
  avatar: string;
  hobbyName: string;
};

export type SkillList = string[];
export type SkillLogos = string[];
export type Skills = {
  list: SkillList;
  logos: SkillLogos;
};

export type Description = string[];

export enum HistoryType {
  WORK = "work",
  EDUCATION = "education",
}

export type History = {
  _id: string;
  title: string;
  subTitle: string;
  location?: string;
  startingDate: number;
  endingDate: number;
  description?: string[];
  footer?: string[];
  type: HistoryType;
};

export type LanguagesType = {
  _id?: string;
  name: string;
  level: string;
};

export type NameAndPositionType = {
  firstName: string;
  lastName: string;
  position: string;
};

export type Cv = {
  _id: string;
  avatar: Avatar;
  contact: Contact;
  skills: Skills;
  description: Description;
  languages: LanguagesType[];
  interests: InterestsType[];
  history: History[];
} & NameAndPositionType;
