import { configureStore } from "@reduxjs/toolkit";
import sideBarReducer from "./sideBar/sidebar";
import cvReducer from "./cv/cv";

const store = configureStore({
  reducer: {
    sideBar: sideBarReducer,
    cv: cvReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
