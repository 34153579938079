import styled from "styled-components";

export const AvatarContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export const AvatarImageContainer = styled.div`
  background-color: white;
  border-radius: 100%;
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CVImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
`;

export const AddAvatar = styled.img.attrs({ src: "/images/AddButton.svg" })`
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  right: 15px;
  bottom: 5px;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    display: none;
  }
`;
