import {
  AddIcon,
  SelectedImagesContainer,
  SkillLogoImage,
  SkillsContainer,
  SkillsImageContainer,
  SkillsListContainer,
  SkillsListPhotos,
  SkillsSectionContainer,
} from "./SkillsSectionStyled";
import { useState } from "react";
import { TemplateModal } from "../../../../muiComponents/TemplateModal/TemplateModal";
import { MultiLineField } from "../../../../muiComponents/InputField/MultiLineField";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Skills } from "../../../../../redux/cv/cvTypes";
import { SkillsImageModal } from "./SkillsImageModal";
import { setCvSkills } from "../../../../../redux/cv/cv";
import { isEmptyObject } from "../../../../../utils";
import { useApi } from "../../../../../providers/Api";

export const SkillsSection = () => {
  const [open, setOpen] = useState(false);
  const { skills, _id } = useSelector((state: RootState) => state.cv) || {};
  const dispatch = useDispatch();

  const { updateSkills, deleteSkills } = useApi();

  const [inputValues, setInputValues] = useState<Skills>({
    list: [],
    logos: Array(5).fill(""),
  });

  const handleChange = (value: string[]) => {
    setInputValues({
      list: value,
      logos: inputValues.logos,
    });
  };
  const handleOpen = () => {
    setInputValues({
      list: skills?.list || [],
      logos: skills?.logos?.length ? skills.logos : Array(5).fill(""),
    });
    setOpen(true);
  };
  const handleCancel = () => {
    setInputValues({ list: [], logos: Array(5).fill("") });
    setOpen(false);
  };

  // SELECT IMAGES

  const [imageModalOpen, setImageModalOpen] = useState(false);

  const [currentSkillIndex, setCurrentSkillIndex] = useState<number | null>(
    null
  );

  const handleClose = () => {
    setImageModalOpen(false);
  };

  const selectImage = (url: string) => {
    setInputValues({
      ...inputValues,
      logos: inputValues.logos.map((img, index) => {
        if (index === currentSkillIndex) {
          return url;
        }

        return img;
      }),
    });

    setImageModalOpen(false);
    setCurrentSkillIndex(null);
  };

  const handleSave = () => {
    if (_id) {
      updateSkills({ id: _id, ...inputValues }).then((res) => {
        dispatch(setCvSkills(inputValues));
        handleCancel();
      });
    }
  };

  const handleDelete = () => {
    if (_id) {
      deleteSkills(_id).then(() => {
        dispatch(setCvSkills({ logos: [], list: [] }));
      });
    }
  };

  return (
    <>
      <SkillsSectionContainer
        handleOpen={handleOpen}
        title="Skills"
        handleDelete={handleDelete}
        isEditable={isEmptyObject(skills!)}
      >
        <>
          <SkillsContainer>
            <SkillsListPhotos>
              {skills?.logos.map((item, index) => (
                <SkillLogoImage src={item} key={index} />
              ))}
            </SkillsListPhotos>
            <SkillsListContainer>
              {skills?.list.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </SkillsListContainer>
          </SkillsContainer>
        </>
      </SkillsSectionContainer>
      <TemplateModal
        open={open}
        onCancel={handleCancel}
        onSave={handleSave}
        title="Skills"
      >
        <SelectedImagesContainer>
          {inputValues?.logos.map((url, index) => (
            <SkillsImageContainer key={index}>
              <AddIcon
                itemProp={url}
                onClick={() => {
                  setImageModalOpen(true);
                  setCurrentSkillIndex(index);
                }}
              />
            </SkillsImageContainer>
          ))}
        </SelectedImagesContainer>

        <MultiLineField
          rows={10}
          label="Skills"
          placeholder="Write you skills. Press enter after every skill you type, make sure the skills you write is not longer than 18 characters and recommended skills length is 15"
          onChange={handleChange}
          name="skills"
          value={inputValues.list}
        />
        <SkillsImageModal
          open={imageModalOpen}
          handleClose={handleClose}
          selectImage={selectImage}
        />
      </TemplateModal>
    </>
  );
};
