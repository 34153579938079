import { LinearProgress } from "@mui/material";
import styled from "styled-components";

export const LoadingBar = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    width: 100%;
    height: 6px;
    position: fixed;
    top: 0;
    left: 0;
  }
`;
