import { RefObject, useState } from "react";
import {
  BackToDashboardContainer,
  ButtonPrimary,
  ButtonSecondary,
  ButtonText,
  CvNameText,
  DashboardHeader,
  DashboardUserNameContainer,
  Header,
  HeaderToolButtonsContainer,
  ModalText,
  ProfileButton,
} from "./DashboardTemplateContentStyled";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { useSetPreviewContext } from "../../../../context/PreviewPDFContext";
import { setCv } from "../../../../redux/cv/cv";
import { deleteCvById } from "../../../../api";
import { removeOneItemFromList } from "../../../../redux/sideBar/sidebar";
import { useReactToPrint } from "react-to-print";
import { RootState } from "../../../../redux/store";
import { TemplateModal } from "../../../muiComponents/TemplateModal/TemplateModal";

type Props = {
  cvTemplateRef: RefObject<HTMLDivElement>;
  isCvSelected: boolean;
};

export const DashboardTemplateContent = ({
  cvTemplateRef,
  isCvSelected,
}: Props) => {
  const dispatch = useDispatch();
  const { togglePreview, showPreview, hidePreview } = useSetPreviewContext();
  const id = useSelector((state: RootState) => state.cv?._id);
  const firstName = useSelector((state: RootState) => state.cv?.firstName);
  const lastName = useSelector((state: RootState) => state.cv?.lastName);

  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    deleteCvById(id)
      .then(() => {
        dispatch(removeOneItemFromList(id!));
        setOpen(false);
      })
      .then(() => {
        dispatch(setCv(null as any));
      });
  };

  const handlePrint = useReactToPrint({
    content: () => cvTemplateRef.current,
    onAfterPrint: () => showPreview(),
  });

  return (
    <Header>
      <DashboardUserNameContainer>
        {isCvSelected && (
          <BackToDashboardContainer
            onClick={() => {
              dispatch(setCv(null as any));
            }}
          >
            <ArrowBackIosIcon />
            <p>Back to dashboard</p>
          </BackToDashboardContainer>
        )}

        <ProfileButton />
      </DashboardUserNameContainer>
      {isCvSelected && (
        <DashboardHeader>
          <CvNameText>
            <h2>{firstName}</h2>
            <h2>{lastName}</h2>
          </CvNameText>
          <HeaderToolButtonsContainer>
            <ButtonText onClick={togglePreview}>Preview</ButtonText>

            <ButtonPrimary
              onClick={async () => {
                await hidePreview();
                await handlePrint();
              }}
            >
              Export
            </ButtonPrimary>

            <ButtonSecondary onClick={handleOpen}>Delete</ButtonSecondary>
            <TemplateModal
              open={open}
              onCancel={handleCancel}
              onSave={handleDelete}
              extraButtonName="Yes"
            >
              <ModalText>
                Are you sure you want to delete this document?
              </ModalText>
            </TemplateModal>
          </HeaderToolButtonsContainer>
        </DashboardHeader>
      )}
    </Header>
  );
};
