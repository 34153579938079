import {
  ContentContainer,
  CvCard,
  CVsListContainer,
  Footer,
  CvImage,
  CvName,
  CvAvatar,
  SelectedBarMobile,
} from "./ContentStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useEffect } from "react";
import {
  setItemList,
  checkItemForRemoval,
} from "../../../../redux/sideBar/sidebar";
import Checkbox from "@mui/material/Checkbox";
import { DeleteModal } from "./DeleteModal/DeleteModal";
import { setCv } from "../../../../redux/cv/cv";
import { useApi } from "../../../../providers/Api";

export const Content = () => {
  const dispatch = useDispatch();
  const list = useSelector((state: RootState) => state.sideBar.list);
  const cvId = useSelector((state: RootState) => state.cv?._id);
  const avatar = useSelector((state: RootState) => state.cv?.avatar);

  const { getCVs, getCvById } = useApi();

  useEffect(() => {
    getCVs().then((data) => {
      dispatch(
        setItemList(
          data.map((item) => ({
            ...item,
            cvId: item._id,
            selectedForRemoval: false,
          }))
        )
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, avatar]);
  const toggle = useSelector((state: RootState) => state.sideBar.collapsed);
  const setDeleted = useSelector((state: RootState) => state.sideBar.deleted);

  return (
    <ContentContainer isToggle={toggle}>
      <CVsListContainer>
        {list.map((item) => (
          <CvCard
            key={item.cvId}
            onClick={() => {
              getCvById(item.cvId).then((data) => {
                dispatch(setCv(data));
              });
            }}
          >
            {setDeleted && (
              <Checkbox
                checked={item.selectedForRemoval}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  dispatch(
                    checkItemForRemoval({
                      cvId: item.cvId,
                      value: e.target.checked,
                    })
                  );
                }}
              />
            )}
            <CvImage>
              <CvAvatar
                src={item.avatar ? item.avatar.url : "/images/AvatarPH.png"}
              />
            </CvImage>
            {toggle && (
              <CvName
                selected={cvId === item.cvId}
              >{`${item.firstName} ${item.lastName}`}</CvName>
            )}
            {!toggle && cvId === item.cvId && <SelectedBarMobile />}
          </CvCard>
        ))}
      </CVsListContainer>
      {setDeleted && <Footer>{<DeleteModal />}</Footer>}
    </ContentContainer>
  );
};
