import React from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import {
  ModalContainer,
  DeleteButton,
  DeleteButtonImage,
  ButtonsContainer,
  CancelButtonModal,
  DeleteButtonModal,
  ModalTitle,
  ModalSubtitle,
} from "./DeleteModalStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

import {
  removeItemsFromList,
  toggleDeleted,
} from "../../../../../redux/sideBar/sidebar";
import { useApi } from "../../../../../providers/Api";
import { setCv } from "../../../../../redux/cv/cv";

export const DeleteModal = () => {
  const { deleteCVs } = useApi();

  const dispatch = useDispatch();
  const selectedForDelete = useSelector(
    (state: RootState) => state.sideBar.list
  )
    .filter((item) => item.selectedForRemoval)
    .map((item) => item.cvId);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <DeleteButton onClick={handleOpen}>
        <DeleteButtonImage />
        Delete Selected Items
      </DeleteButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <ModalTitle />
          <ModalSubtitle />
          <ButtonsContainer>
            <CancelButtonModal onClick={handleClose}>
              <Typography>No</Typography>
            </CancelButtonModal>
            <DeleteButtonModal
              onClick={() => {
                if (selectedForDelete.length) {
                  deleteCVs(selectedForDelete)
                    .then(() => {
                      dispatch(removeItemsFromList(selectedForDelete));
                      dispatch(toggleDeleted());
                      dispatch(setCv(null as any));
                    })
                    .finally(handleClose);
                }
              }}
            >
              <Typography>Yes</Typography>
            </DeleteButtonModal>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
    </div>
  );
};
