import styled from "styled-components";
import { colors } from "../../../../../style/colors";
import { SidebarSection } from "../SidebarSection/SidebarSection";

export const InterestsSectionContainer = styled(SidebarSection)``;

export const InterestsImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 600px;
  padding: 12px 0px;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 16px;
  gap: 10px;
`;

export const InterestImageWithTextContainer = styled.div<{
  checkSelected: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: ${(props) =>
    props.checkSelected ? `3px solid ${colors.blue500}` : "none"};
  img {
    border-radius: 10px;
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
`;

export const InterestSectionBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;

  img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    background: white;
  }
`;

export const AddIcon = styled.img.attrs({
  src: "/images/AddButton.svg",
})`
  width: 40px;
  cursor: pointer;
`;

export const AddIconPreview = styled.img`
  width: 45px;
  cursor: pointer;
  padding: 3px;
`;

export const AddMoreHobbiesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0px 30px 10px;
  p {
    font-size: 22px;
    font-weight: bolder;
  }
`;

export const ExtraModalAddContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const ExtraInputContainer = styled.div`
  display: flex;
  gap: 20px;
`;
export const ErrorSizeMessage = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
  color: red;
`;

export const InterestImageContainer = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  background-color: ${colors.lightGrey};
  padding: 10px;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    display: none;
  }
`;

export const ExtraInterestModalText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;

  h1 {
    margin-bottom: 20px;
    font-weight: bold;
  }

  p {
    font-size: 20px;
  }
`;

export const FirstModalTitle = styled.h2`
  font-weight: bolder;
  span {
    font-weight: thin;
  }
`;
