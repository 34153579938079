import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { LoginPage } from "./Pages/LoginPage/LoginPage";
import { DashboardInterface } from "./Pages/DashboardInterface/DashboardInterface";
import store from "./redux/store";
import { Provider } from "react-redux";
import { GlobalStyle } from "./style/GlobalStyles";
import { hasLoginToken } from "./utils";
import { ApiProvider } from "./providers/Api";
import { useEffect } from "react";
import ReactGA from "react-ga4";

export const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-CVWKVZYT5F");
  }, []);

  return (
    <ApiProvider>
      <Provider store={store}>
        <Router>
          <GlobalStyle />
          <Routes>
            <Route
              path="/"
              element={
                hasLoginToken() ? (
                  <DashboardInterface />
                ) : (
                  <Navigate replace to="/login" />
                )
              }
            />
            <Route path="/login" element={<LoginPage />}></Route>
          </Routes>
        </Router>
      </Provider>
    </ApiProvider>
  );
};
