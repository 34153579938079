import { TextField, Typography } from "@mui/material";
import styled from "styled-components";

export const NameAndPositionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const NameAndPositionText = styled.div`
  height: 10%;
  color: white;
  margin-bottom: 30px;
  width: 100%;
`;

export const NameSurname = styled(Typography).attrs({
  variant: "h5",
})`
  &.MuiTypography-root {
    font-weight: bold;
  }
`;

export const Occupation = styled(Typography)`
  font-size: 24px;
  font-weight: bold;
`;

export const EditIcon = styled.img.attrs({ src: "/images/EditIcon.svg" })`
  width: 28px;
  cursor: pointer;
`;

// TODO: move to shared components
export const Input = styled(TextField)`
  &.MuiTextField-root {
    height: 56px;
    margin-top: 0px;
    margin-bottom: 10px;
    label {
      color: #57b3f1;
    }
    fieldset {
      border-color: #57b3f1;
    }
    input {
      color: #57b3f1;
    }
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #57b3f1;
    }
  }
`;
