import { TextField } from "@mui/material";
import styled from "styled-components";

export const DateInput = styled(TextField)`
  &.MuiTextField-root {
    width: 283px;
    height: 56px;
    label {
      color: #57b3f1;
    }
    fieldset {
      border-color: #57b3f1;
    }
    input {
      color: #57b3f1;
    }
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #57b3f1;
    }
  }
`;

export const DateInputContainer = styled.div`
  display: flex;
  gap: 40px;
  /* margin-bottom: 0px; */
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
