import styled from "styled-components";

export const LoginPageMainContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
`;

export const LogoSideContainer = styled.div`
  height: 40%;
  display: flex;
  align-items: center;
`;

export const WaveSideContainer = styled.div`
  height: 60%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
`;

export const Wave = styled.img.attrs({ src: "/images/wave.svg" })`
  width: 100%;
  position: absolute;
  bottom: -40px;
`;

export const TechBreezeLogo = styled.img.attrs({
  src: "/images/Tagline.svg",
  alt: "tech-breeze-logo",
})`
  width: 300px;
`;
