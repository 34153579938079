import {
  AddIcon,
  DeleteLanguageButton,
  LanguageBar,
  LanguageBox,
  LanguagePercentContainer,
  LanguagesContainer,
  LanguagesInputBox,
  LanguagesSectionContainer,
  ModalIputs,
  SelectInput,
} from "./LanguageSectionStyled";
import { useState } from "react";
import { TemplateModal } from "../../../../muiComponents/TemplateModal/TemplateModal";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TextField } from "../../../../muiComponents/InputField/TextField";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { LanguagesType } from "../../../../../redux/cv/cvTypes";
import { setCvLanguages } from "../../../../../redux/cv/cv";
import { checkEmptyArray } from "../../../../../utils";
import { useApi } from "../../../../../providers/Api";

export const LanguageSection = () => {
  const dispatch = useDispatch();
  const { _id } = useSelector((state: RootState) => state.cv) || {};
  const languages = useSelector((state: RootState) => state.cv?.languages);

  const [open, setOpen] = useState(false);
  const [languageList, setLanguageList] = useState<LanguagesType[]>([]);

  const { updateLanguages, deleteLanguages } = useApi();

  const handleCancel = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
    if (languages && languages.length > 0) {
      setLanguageList(languages);
    } else {
      setLanguageList([{ name: "", level: "" }]);
    }
  };

  const handleClick = () => {
    if (languageList.length >= 6) {
      return;
    }
    setLanguageList([...languageList, { name: "", level: "" }]);
  };

  const setLanguageAttributes = (
    i: number,
    attributeName: string,
    attributeValue: string
  ) => {
    setLanguageList(
      languageList.map((element, index) => {
        if (i === index) {
          return { ...element, [attributeName]: attributeValue };
        }
        return element;
      })
    );
  };

  const handleSave = () => {
    if (_id) {
      updateLanguages({ id: _id, languages: languageList }).then(() => {
        dispatch(setCvLanguages(languageList));
        handleCancel();
      });
    }
  };

  const handleDelete = () => {
    if (_id) {
      deleteLanguages(_id).then(() => {
        dispatch(setCvLanguages([]));
      });
    }
  };

  function getBarWidth(level: string) {
    switch (level) {
      case "basic":
        return "20%";
      case "good":
        return "40%";
      case "intermediate":
        return "60%";
      case "advance":
        return "80%";
      case "native":
        return "100%";
      default:
        return "0%";
    }
  }

  return (
    <>
      <LanguagesSectionContainer
        handleOpen={handleOpen}
        handleDelete={handleDelete}
        title="Languages"
        isEditable={checkEmptyArray(languages)}
      >
        <LanguagesContainer>
          {languages?.map((item, index) => (
            <LanguageBox key={index}>
              <LanguagePercentContainer>
                <LanguageBar itemProp={getBarWidth(item.level)} />
              </LanguagePercentContainer>
              <h2>{item.name}</h2>
            </LanguageBox>
          ))}
        </LanguagesContainer>
      </LanguagesSectionContainer>
      <TemplateModal
        open={open}
        onCancel={handleCancel}
        onSave={handleSave}
        title="Languages"
      >
        <AddIcon onClick={handleClick} />
        <LanguagesInputBox>
          {languageList?.map((item, index) => (
            <ModalIputs key={index}>
              <DeleteLanguageButton
                onClick={() => {
                  setLanguageList(languageList.filter((_, i) => i !== index));
                }}
              >
                <div />
              </DeleteLanguageButton>
              <TextField
                label="Language"
                onChange={(e) => {
                  setLanguageAttributes(index, "name", e);
                }}
                value={item.name}
                name="language"
              />
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    backgroundColor: "white",
                    color: "#57b3f1",
                    padding: "0px 5px",
                  }}
                >
                  Level
                </InputLabel>
                <SelectInput
                  sx={{ color: "#57b3f1" }}
                  fullWidth
                  value={item.level}
                  onChange={(e: any) => {
                    setLanguageAttributes(index, "level", e.target.value);
                  }}
                >
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="good">Conversational</MenuItem>
                  <MenuItem value="intermediate">Intermediate</MenuItem>
                  <MenuItem value="advance">Advance</MenuItem>
                  <MenuItem value="native">Perfect</MenuItem>
                </SelectInput>
              </FormControl>
            </ModalIputs>
          ))}
        </LanguagesInputBox>
      </TemplateModal>
    </>
  );
};
