import React, { ReactNode } from "react";
import { LoginButtonStyled } from "./LoginButtonStyles";

type Props = {
  children?: ReactNode;
};

export const LoginButton = ({ children }: Props) => {
  return (
    <LoginButtonStyled variant="outlined" type="submit">
      {children}
    </LoginButtonStyled>
  );
};
