import { TechBreezeLogo, Wave } from "./LoginPageStyles";
import LoginForm from "../../components/LoginForm/LoginForm";
import { LoginPageMainContainer } from "./LoginPageStyles";
import { LogoSideContainer } from "./LoginPageStyles";
import { WaveSideContainer } from "./LoginPageStyles";

export const LoginPage = () => {
  return (
    <LoginPageMainContainer>
      <LogoSideContainer>
        <TechBreezeLogo />
      </LogoSideContainer>
      <WaveSideContainer>
        <Wave />
        <LoginForm />
      </WaveSideContainer>
    </LoginPageMainContainer>
  );
};
