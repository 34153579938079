import { ReactNode } from "react";
import { usePreviewContext } from "../../../../../context/PreviewPDFContext";
import {
  DividerAndIcon,
  SectionContentContainer,
  AddIcon,
  EditIcon,
  DeleteIcon,
  DividerMui,
  Title,
} from "./SideBarSectionStyled";

type Props = {
  children: ReactNode;
  handleOpen?: () => void;
  isEditable?: boolean;
  title: string;
  handleDelete?: () => void;
  pageBreak?: boolean;
};

export const SidebarSection = ({
  children,
  handleOpen,
  handleDelete,
  isEditable,
  title,
  pageBreak,
}: Props) => {
  const preview = usePreviewContext();
  return (
    <>
      <DividerAndIcon>
        <DividerMui />
        {preview ? (
          isEditable ? (
            <>
              <EditIcon onClick={handleOpen} />
              <DeleteIcon onClick={handleDelete} />
            </>
          ) : (
            <AddIcon onClick={handleOpen} />
          )
        ) : null}
      </DividerAndIcon>
      <Title>{title}</Title>
      <SectionContentContainer>
        {isEditable ? children : null}
      </SectionContentContainer>
    </>
  );
};
