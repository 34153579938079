import { useEffect, useRef, useState } from "react";

const calculate = (value: number, breakingPoint: number) => {
  return Math.floor(value / breakingPoint) + 1;
};

export const useResizeHook = () => {
  const [size, setSize] = useState(1120);
  const refSide = useRef<HTMLDivElement>(null);
  const refMain = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refSide.current && refMain.current) {
      const observer = new ResizeObserver(() => {
        if (refSide.current!.clientHeight > refMain.current!.clientHeight) {
          setSize(calculate(refSide.current!.clientHeight, 1095) * 1120);
        } else {
          setSize(calculate(refMain.current!.clientHeight, 1091) * 1120);
        }
      });
      observer.observe(refSide.current!);
      observer.observe(refMain.current!);
    }

    // eslint-disable-next-line
  }, []);

  return { size, refSide, refMain };
};
