import styled from "styled-components";
import { colors } from "../../../../style/colors";

export const CVTemplateContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  align-self: center;

  > div {
    min-height: ${(props) => props.itemProp};
  }
`;

export const CVSideInformationsContainer = styled.div`
  width: 283px;
  background-color: ${colors.blue300};
  padding: 16px;
`;
export const CVSideInformationsForResize = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CVMainInformationsContainer = styled.div`
  width: 514px;
  background-color: ${colors.paperGrey};
  padding: 15px;
`;
export const CVMainInformationsForResize = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CVDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-left: 50px;
  padding: 36px 0px;
`;

export const BackToDashboardContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 18px;
    padding-bottom: 2px;
    color: ${colors.blue500};
  }
  p {
    font-size: 18px;
    color: ${colors.blue500};
    font-weight: bold;
  }
`;

export const HeaderToolButtonsContainer = styled.div`
  display: flex;
  height: 50px;
  margin-right: 50px;
`;
export const ButtonPrimary = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 67px;
  cursor: pointer;
  margin: 3px;
  background-color: ${colors.blue300};
  border: 2px solid ${colors.blue300};
  color: white;
`;

export const ButtonSecondary = styled(ButtonPrimary)`
  background-color: ${colors.lightGrey};
  color: ${colors.blue500};
  border-color: ${colors.blue500};
`;

export const ButtonText = styled(ButtonPrimary)`
  background-color: ${colors.lightGrey};
  border-color: ${colors.lightGrey};
  color: ${colors.blue500};
`;

export const ModalText = styled.p`
  text-align: center;
  font-size: 30px;
  padding-top: 100px;
  font-weight: bold;
  color: ${colors.blue300};
`;

export const DashboardUserNameContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
