import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Content } from "./Content/Content";
import { ListContainer } from "./DashboardListStyles";
import { Header } from "./Header/Header";

export const DashboardList = () => {
  const collapsed = useSelector((state: RootState) => state.sideBar.collapsed);

  return (
    <ListContainer collapsed={collapsed}>
      <Header />
      <Content />
    </ListContainer>
  );
};
