import { Divider, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "../../../../../../style/colors";

export const WorkHistoryMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  /* padding: 10px; */
`;

export const Title = styled(Typography).attrs({
  variant: "h5",
  children: "Work History",
})``;

export const DividerAndIcon = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  gap: 10px;
  width: 100%;
`;

export const AddIcon = styled.img.attrs({ src: "/images/AddButton.svg" })`
  width: 35px;
`;

export const EditIcon = styled.img.attrs({ src: "/images/EditIcon.svg" })`
  width: 30px;
`;

export const DeleteIcon = styled.img.attrs({ src: "/images/DeleteIcon.svg" })`
  width: 30px;
  margin-left: 10px;
`;

export const DividerMui = styled(Divider)`
  background-color: ${colors.blue500};
  margin-bottom: 16px;
  margin-left: 16px;
  flex: 1;
`;

export const DateAndJobDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  position: relative;
  page-break-inside: always;
`;

// Date
export const DateContainer = styled.div`
  width: 20%;
  p {
    margin-top: 10px;
    text-align: center;
    color: ${colors.blue300};
    font-weight: bold;
  }
`;

export const JobDescriptionContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;

// Occupation
export const OccupationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OccupationTitle = styled(Typography).attrs({
  variant: "h6",
})`
  color: ${colors.blue500};
  margin-right: auto;
`;

// Responsibilities
export const ResponsibilitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-break: anywhere;
`;

export const ResponsibilitiesTitle = styled(Typography).attrs({
  variant: "h6",
  children: "Responsibilities",
})``;

// Technologies
export const TechnologiesContainer = styled.div`
  margin-top: 15px;
  display: flex;
  line-break: anywhere;
  p {
    line-height: 1.4rem;
  }
  span {
    margin-left: 5px;
  }
`;

export const TechTit = styled.p`
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  span {
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
  }
`;

export const TechnologiesTitle = styled(Typography).attrs({
  variant: "h6",
  children: "Technologies: ",
})`
  &.MuiTypography-root {
    line-height: 1.3rem;
  }
`;

export const SpecialInputFirst = styled(TextField).attrs({
  multiline: true,
  rows: "3",
})`
  &.MuiTextField-root {
    width: 500px;
    height: 56px;
    margin-bottom: 50px;
    label {
      color: #57b3f1;
    }
    fieldset {
      border-color: #57b3f1;
    }
    input {
      color: #57b3f1;
    }
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #57b3f1;
    }
  }
`;
export const SpecialInputSecond = styled(TextField).attrs({
  multiline: true,
  rows: "3",
})`
  &.MuiTextField-root {
    width: 500px;
    height: 56px;
    margin-bottom: 50px;
    label {
      color: #57b3f1;
    }
    fieldset {
      border-color: #57b3f1;
    }
    input {
      color: #57b3f1;
    }
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #57b3f1;
    }
  }
`;
export const Input = styled(TextField)`
  &.MuiTextField-root {
    width: 500px;
    height: 56px;

    label {
      color: #57b3f1;
    }
    fieldset {
      border-color: #57b3f1;
    }
    input {
      color: #57b3f1;
    }
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #57b3f1;
    }
  }
`;
export const DateInput = styled(TextField)`
  &.MuiTextField-root {
    width: 230px;
    height: 56px;
    label {
      color: #57b3f1;
    }
    fieldset {
      border-color: #57b3f1;
    }
    input {
      color: #57b3f1;
    }
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #57b3f1;
    }
  }
`;

export const DateInputContainer = styled.div`
  display: flex;
  width: 500px;
  gap: 40px;
`;

export const OccupationHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditAndDeleteContainer = styled.div`
  display: flex;
`;
