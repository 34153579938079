import styled from "styled-components";
import { colors } from "../../../../style/colors";

export const ContentContainer = styled.div<{ isToggle: boolean }>`
  height: 80%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (!props.isToggle ? "center" : "none")};
`;

export const CVsListContainer = styled.div`
  height: 93%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 10px;
`;

export const CvCard = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  height: 42px;
`;

export const CvImage = styled.div`
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  background: white;
  overflow: hidden;
`;

export const CvAvatar = styled.img.attrs({ alt: "cv-avatar" })`
  width: 100%;
  border-radius: 5px;
  border: 2px solid white;
  /* object-fit: fill; */
`;

export const SelectedBarMobile = styled.div`
  height: 80%;
  width: 3px;
  background-color: white;
`;

export const CvName = styled.p<{ selected: boolean }>`
  flex: 1;
  background-color: ${({ selected }) => (selected ? "white" : colors.blue300)};
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ selected }) => (selected ? "black" : "white")};
`;

export const Footer = styled.div`
  height: 7%;
  box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.56);
  background-color: #002b48;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteButton = styled.button`
  cursor: pointer;
  width: 240px;
  font-size: 17px;
  padding: 4px;
  border-radius: 67px;
  border: 2px solid white;
  color: white;
  background-color: ${colors.blue100};
`;

export const DeleteButtonImage = styled.img.attrs({
  src: "/images/deleteIconButton.svg",
  alt: "delete-icon",
})`
  margin-right: 10px;
`;
