import React, { ReactNode } from "react";
import Modal from "@mui/material/Modal";
import {
  ButtonsContainer,
  CancelButton,
  CreateButton,
  ModalContainer,
  ModalTitle,
} from "./TemplateModalStyled";
import { Typography } from "@mui/material";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSave?: () => void;
  onEdit?: () => void;
  children?: ReactNode;
  title?: ReactNode;
  extraButtonName?: string;
};

export const TemplateModal = ({
  open,
  onCancel,
  children,
  title,
  onSave,
  extraButtonName,
}: Props) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        {title && <ModalTitle>{title}</ModalTitle>}
        {children}
        <ButtonsContainer>
          <CancelButton onClick={onCancel}>
            <Typography>Cancel</Typography>
          </CancelButton>
          <CreateButton onClick={onSave}>
            <Typography>{extraButtonName || "Save"}</Typography>
          </CreateButton>
        </ButtonsContainer>
      </ModalContainer>
    </Modal>
  );
};
