export const colors = {
  paperGrey: "#ffffff",
  blue100: "#002B48",
  blue300: "#005DA5",
  blue500: "#57B3F1",
  orange: "#E65200",
  grey: "#D1D1D1",
  red: "#D70034",
  lightGrey: "#F4F4F4",
};
