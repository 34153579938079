import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../storeTypes";

type Avatar = {
  url?: string;
  name?: string;
};

type SideBarListItem = {
  cvId: string;
  avatar?: Avatar | undefined;
  firstName: string;
  lastName: string;
  selectedForRemoval: boolean;
};

type SideBarState = {
  collapsed: boolean;
  deleted: boolean;
  list: SideBarListItem[];
};

const initialState: SideBarState = {
  collapsed: true,
  deleted: false,
  list: [],
};

export const sideBarSlice = createSlice({
  name: "toggle",
  initialState,
  reducers: {
    toggleCollapsed: (state) => {
      state.collapsed = !state.collapsed;
      state.deleted = false;
    },
    toggleDeleted: (state) => {
      state.deleted = !state.deleted;
      for (const item of state.list) {
        item.selectedForRemoval = false;
      }
    },
    setAvatarByItemId: (
      state,
      action: Action<{ id: string; url: string; name: string }>
    ) => {
      const { payload } = action;
      const item = state.list.find((item) => item.cvId === payload.id);
      if (item?.avatar) {
        item.avatar = { url: payload.url, name: payload.name };
      }
    },
    setItemList: (state, action: Action<SideBarListItem[]>) => {
      const { payload } = action;

      state.list = payload;
    },
    addItemToList: (state, action: Action<SideBarListItem>) => {
      const { payload } = action;

      state.list.push(payload);
    },
    checkItemForRemoval: (
      state,
      action: Action<{ cvId: string; value: boolean }>
    ) => {
      const { payload } = action;

      const item = state.list.find((item) => item.cvId === payload.cvId);
      if (item) {
        item.selectedForRemoval = payload.value;
      }
    },
    removeItemsFromList: (state, action: Action<string[]>) => {
      const { payload } = action;

      state.list = state.list.filter((item) => !payload.includes(item.cvId));
    },
    removeOneItemFromList: (state, action: Action<string>) => {
      const { payload } = action;

      state.list = state.list.filter((item) => !payload.includes(item.cvId));
    },
  },
});

export const {
  toggleCollapsed,
  setItemList,
  addItemToList,
  toggleDeleted,
  checkItemForRemoval,
  removeItemsFromList,
  removeOneItemFromList,
  setAvatarByItemId,
} = sideBarSlice.actions;
export default sideBarSlice.reducer;
