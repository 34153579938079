import React, { useState } from "react";

import Modal from "@mui/material/Modal";
import {
  ButtonsContainer,
  CancelButton,
  CreateButton,
  CreateCV,
  Input,
  ModalContainer,
} from "./NewCVModalStyles";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { addItemToList } from "../../../../../redux/sideBar/sidebar";
import { useApi } from "../../../../../providers/Api";

type InputValues = {
  firstName: string;
  lastName: string;
  position: string;
};
export default function BasicModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { createNewCV } = useApi();

  const [inputValues, setInputValues] = useState<InputValues>({
    firstName: "",
    lastName: "",
    position: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <div>
      <CreateCV onClick={handleOpen}>
        <img src="/images/CreateNewCV.svg" alt="createCV" />
      </CreateCV>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <Typography>Create New CV</Typography>
          <Input
            variant="outlined"
            label="First Name"
            name="firstName"
            type="text"
            onChange={handleChange}
          />
          <Input
            variant="outlined"
            label="Last Name"
            name="lastName"
            type="text"
            onChange={handleChange}
          />
          <Input
            variant="outlined"
            label="Position"
            name="position"
            type="text"
            onChange={handleChange}
          />
          <ButtonsContainer>
            <CancelButton onClick={handleClose}>
              <Typography>Cancel</Typography>
            </CancelButton>
            <CreateButton
              onClick={() => {
                createNewCV(
                  inputValues.firstName,
                  inputValues.lastName,
                  inputValues.position
                ).then((data) => {
                  dispatch(
                    addItemToList({
                      cvId: data._id,
                      lastName: data.lastName,
                      firstName: data.firstName,
                      // avatar: data.avatar,
                      selectedForRemoval: false,
                    })
                  );
                  handleClose();
                });
              }}
            >
              <Typography>Create</Typography>
            </CreateButton>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
    </div>
  );
}
