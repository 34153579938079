import styled from "styled-components";
import { colors } from "../../../../../style/colors";
import { SidebarSection } from "../SidebarSection/SidebarSection";

export const SkillsSectionContainer = styled(SidebarSection)``;

export const SelectedImagesContainer = styled.div`
  display: flex;
  gap: 25px;
`;

export const SkillsImageContainer = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  background-color: ${colors.lightGrey};
  padding: 10px;
  cursor: pointer;
`;

export const SkillsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  position: relative;
  page-break-after: always;
`;

export const SkillsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: flex-start;
  margin-left: 5px;
  padding-right: 5px;
  overflow-x: hidden;

  p {
    font-size: 17px;
    color: white;
  }
`;

export const SkillsListPhotos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  gap: 10px;
`;

export const SkillLogoImage = styled.img`
  width: 75%;
  border-radius: 10px;
`;

export const AddIcon = styled.img.attrs((props) => ({
  src: props.itemProp || "/images/AddButton.svg",
}))`
  width: 100px;
`;
