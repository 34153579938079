import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  AddAvatar,
  AvatarContainer,
  AvatarImageContainer,
  ButtonContainer,
  CVImage,
} from "./AvatarSectionStyled";
import { setCvAvatar } from "../../../../../redux/cv/cv";
import { usePreviewContext } from "../../../../../context/PreviewPDFContext";
import { useApi } from "../../../../../providers/Api";
import { ChangeEvent, useState } from "react";
import { setAvatarByItemId } from "../../../../../redux/sideBar/sidebar";
import { Alert, Snackbar } from "@mui/material";

export const AvatarSection = () => {
  const { avatar, _id } = useSelector((state: RootState) => state.cv) || {};
  const preview = usePreviewContext();
  const { updateAvatar } = useApi();
  const dispatch = useDispatch();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleClose = () => {
    setOpenSnackbar(false);
  };

  const onUploadImage = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      const file = target.files[0];
      if (target.files[0].size > 4000000) {
        setOpenSnackbar(true);
      } else {
        if (_id) {
          updateAvatar(file, _id)
            .then((res) => {
              dispatch(setCvAvatar({ url: res, name: file.name }));
              dispatch(
                setAvatarByItemId({ id: _id, url: res, name: file.name })
              );
            })
            .catch((e) => e.message);
        }
      }
    }
  };

  return (
    <>
      <AvatarContainer>
        <AvatarImageContainer>
          <CVImage
            src={avatar?.url ? avatar.url : "/images/NewAvatarPlaceholder.png"}
          />
          <ButtonContainer>
            <label htmlFor="icon-file">
              <input
                type="file"
                accept="image/png, image/jpeg"
                id="icon-file"
                name="icon-file"
                onChange={onUploadImage}
              />
              {preview && <AddAvatar />}
            </label>
          </ButtonContainer>
        </AvatarImageContainer>
      </AvatarContainer>
      <Snackbar open={openSnackbar} autoHideDuration={6000}>
        <Alert onClose={handleClose} severity="error">
          Image size is too large
        </Alert>
      </Snackbar>
    </>
  );
};
