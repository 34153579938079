import styled, { css, keyframes } from "styled-components";
import { LinearProgress } from "@mui/material";
import { colors } from "../../style/colors";

export const FormTitle = styled.h1`
  font-size: 24px;
  color: #57b3f1;
  margin-bottom: 24px;
`;

const Animation = keyframes`
 0% { opacity:0 }
 100% {opacity:1}
 `;

const SecondAnimation = keyframes`
 0% { opacity:0 }
 10% { opacity:0}
 100% {opacity:1}
 `;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 300px;
  margin-bottom: 100px;

  animation: ${(props) =>
    props.itemProp === "login"
      ? css`
          ${SecondAnimation} 2s
        `
      : css`
          ${Animation} 2s
        `};
`;

export const ErrorText = styled.p`
  margin-top: 0px;
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
  color: ${colors.orange};
`;

export const LoadingBar = styled(LinearProgress)`
  margin-top: 30px;
  border-radius: 10px;
  background-color: yellow;
`;

export const LoginLinkMessage = styled.p`
  color: white;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`;
