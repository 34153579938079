import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { LoadingBar } from "../components/muiComponents/LoadingBar/LoadingBar";
import {
  addHistoryItemApi,
  createNewCVApi,
  deleteCvContact,
  deleteCvInterests,
  deleteCvLanguages,
  deleteCVsApi,
  deleteCvSkills,
  deleteHistoryItemApi,
  getCVById,
  getCVsApi,
  updateCvAvatarApi,
  updateCvContact,
  updateCvDescription,
  UpdateCvInterests,
  updateCvLanguages,
  updateCvNameAndPosition,
  updateCvSkills,
  updateHistoryItemApi,
} from "../api";
import {
  addHistoryItemApiType,
  AddHistoryItemResponse,
  CreateNewCvResponse,
  GetCVsResponse,
  UpdateAvatarApiPayload,
  UpdateContactApiPayload,
  UpdateDescriptionApiPayload,
  updateHistoryItemApiPayload,
  UpdateLanguagesApiPayload,
  UpdateNameAndPositionApiPayload,
  UpdateSkillsApiPayload,
} from "../api/types";
import { useTrackPromise } from "./ApiHooks";
import { SnackbarComponent } from "./ApiStyled";
import { Cv, History, InterestsType } from "../redux/cv/cvTypes";

const ApiContext = createContext<{
  getCVs: () => Promise<GetCVsResponse>;
  getCvById: (id: string) => Promise<Cv>;
  createNewCV: (
    firstName: string,
    lastName: string,
    position: string
  ) => Promise<CreateNewCvResponse>;
  deleteCVs: (ids: string[]) => Promise<void>;
  updateContact: (payload: UpdateContactApiPayload) => Promise<void>;
  deleteContact: (id: string) => Promise<void>;
  updateNameAndPosition: (
    payload: UpdateNameAndPositionApiPayload
  ) => Promise<void>;
  updateLanguages: (payload: UpdateLanguagesApiPayload) => Promise<void>;
  deleteLanguages: (id: string) => Promise<void>;
  updateSkills: (payload: UpdateSkillsApiPayload) => Promise<void>;
  deleteSkills: (id: string) => Promise<void>;
  updateInterests: (payload: {
    id: string;
    interests: InterestsType[];
  }) => Promise<void>;
  deleteInterests: (id: string) => Promise<void>;
  updateDescription: (payload: UpdateDescriptionApiPayload) => Promise<void>;
  addHistoryItem: (
    payload: addHistoryItemApiType
  ) => Promise<AddHistoryItemResponse>;
  updateHistoryItem: (payload: updateHistoryItemApiPayload) => Promise<History>;
  deleteHistoryItem: (cvId: string, _id: string) => Promise<void>;
  updateAvatar: (file: File, _id: string) => Promise<any>;
}>({
  getCVs: () => Promise.resolve([]),
  getCvById: () => Promise.resolve({} as Cv),
  createNewCV: () => Promise.resolve({} as CreateNewCvResponse),
  deleteCVs: () => Promise.resolve(),
  updateContact: () => Promise.resolve(),
  deleteContact: () => Promise.resolve(),
  updateNameAndPosition: () => Promise.resolve(),
  updateLanguages: () => Promise.resolve(),
  deleteLanguages: () => Promise.resolve(),
  updateSkills: () => Promise.resolve(),
  deleteSkills: () => Promise.resolve(),
  updateInterests: () => Promise.resolve(),
  deleteInterests: () => Promise.resolve(),
  updateDescription: () => Promise.resolve(),
  addHistoryItem: () => Promise.resolve({} as AddHistoryItemResponse),
  updateHistoryItem: () => Promise.resolve({} as History),
  deleteHistoryItem: () => Promise.resolve(),
  updateAvatar: () => Promise.resolve(),
});

export const useApi = () => useContext(ApiContext);

type Props = {
  children: ReactNode;
};

export const ApiProvider = ({ children }: Props) => {
  const { promiseInProgress, promiseError, trackPromise, resetPromiseError } =
    useTrackPromise();

  const getCVs = useCallback(() => {
    return trackPromise(getCVsApi());
    // eslint-disable-next-line
  }, []);

  const getCvById = useCallback((id: string) => {
    return trackPromise(getCVById(id));
    // eslint-disable-next-line
  }, []);

  const createNewCV = useCallback(
    (firstName: string, lastName: string, position: string) => {
      return trackPromise(createNewCVApi(firstName, lastName, position));
    },
    // eslint-disable-next-line
    []
  );

  const deleteCVs = useCallback((ids: string[]) => {
    return trackPromise(deleteCVsApi(ids));
    // eslint-disable-next-line
  }, []);

  const updateContact = useCallback((payload: UpdateContactApiPayload) => {
    return trackPromise(updateCvContact(payload));
    // eslint-disable-next-line
  }, []);

  const deleteContact = useCallback((id: string) => {
    return trackPromise(deleteCvContact(id));
    // eslint-disable-next-line
  }, []);

  const updateNameAndPosition = useCallback(
    (payload: UpdateNameAndPositionApiPayload) => {
      return trackPromise(updateCvNameAndPosition(payload));
    },
    // eslint-disable-next-line
    []
  );

  const updateLanguages = useCallback((payload: UpdateLanguagesApiPayload) => {
    return trackPromise(updateCvLanguages(payload));
    // eslint-disable-next-line
  }, []);

  const deleteLanguages = useCallback((id: string) => {
    return trackPromise(deleteCvLanguages(id));
    // eslint-disable-next-line
  }, []);

  const updateSkills = useCallback((payload: UpdateSkillsApiPayload) => {
    return trackPromise(updateCvSkills(payload));
    // eslint-disable-next-line
  }, []);

  const deleteSkills = useCallback((id: string) => {
    return trackPromise(deleteCvSkills(id));
    // eslint-disable-next-line
  }, []);

  const updateInterests = useCallback(
    (payload: { id: string; interests: InterestsType[] }) => {
      return trackPromise(UpdateCvInterests(payload));
    },
    // eslint-disable-next-line
    []
  );

  const deleteInterests = useCallback((id: string) => {
    return trackPromise(deleteCvInterests(id));
    // eslint-disable-next-line
  }, []);

  const updateDescription = useCallback(
    (payload: UpdateDescriptionApiPayload) => {
      return trackPromise(updateCvDescription(payload));
    },
    // eslint-disable-next-line
    []
  );

  const addHistoryItem = useCallback((payload: addHistoryItemApiType) => {
    return trackPromise(addHistoryItemApi(payload));
    // eslint-disable-next-line
  }, []);

  const updateHistoryItem = useCallback(
    (payload: updateHistoryItemApiPayload) => {
      return trackPromise(updateHistoryItemApi(payload));
    },
    // eslint-disable-next-line
    []
  );

  const deleteHistoryItem = useCallback((cvId: string, _id: string) => {
    return trackPromise(deleteHistoryItemApi(cvId, _id));
    // eslint-disable-next-line
  }, []);

  const updateAvatar = useCallback((file: File, _id: string) => {
    return trackPromise(updateCvAvatarApi(file, _id));
    // eslint-disable-next-line
  }, []);

  const providerValue = useMemo(
    () => ({
      getCVs,
      createNewCV,
      getCvById,
      deleteCVs,
      updateContact,
      deleteContact,
      updateNameAndPosition,
      updateLanguages,
      deleteLanguages,
      updateSkills,
      deleteSkills,
      updateInterests,
      deleteInterests,
      updateDescription,
      addHistoryItem,
      updateHistoryItem,
      deleteHistoryItem,
      updateAvatar,
    }),
    [
      getCVs,
      createNewCV,
      getCvById,
      deleteCVs,
      updateContact,
      deleteContact,
      updateNameAndPosition,
      updateLanguages,
      deleteLanguages,
      updateSkills,
      deleteSkills,
      updateInterests,
      deleteInterests,
      updateDescription,
      addHistoryItem,
      updateHistoryItem,
      deleteHistoryItem,
      updateAvatar,
    ]
  );

  return (
    <ApiContext.Provider value={providerValue}>
      {promiseInProgress && <LoadingBar />}
      <SnackbarComponent
        open={promiseError}
        onClose={resetPromiseError}
        autoHideDuration={4000}
        message="Something went wrong ..."
      />
      {children}
    </ApiContext.Provider>
  );
};
