import styled from "styled-components";
import { TextField } from "@mui/material";

export const StyledInput = styled(TextField)`
  &.MuiTextField-root {
    min-height: 56px;
    margin-top: 0px;
    margin-bottom: 10px;
    label {
      color: #57b3f1;
    }
    fieldset {
      border-color: #57b3f1;
    }
    input {
      color: #57b3f1;
    }
  }
`;
